import React, { createContext, Fragment, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import { Outlet } from "react-router-dom";
import { Provider } from "react-redux";
import Store from "../../redux/store/store";
import { getProjectsForUser } from "../../network/ApiAxios";

const AppContext = createContext({});
export { AppContext };

export default function App() {
  const { invitationId } = useParams();
  const [isSidebarToggled, setIsSidebarToggled] = React.useState(true);

  const [projectCodeArchive, setProjectCodeArchive] = React.useState<any>(undefined);
  const [projectKey, setProjectKey] = React.useState<string>(""); //projectId_projectEnvId

  const openCloseSidebar = () => {
    setIsSidebarToggled((prev) => !prev);
  };

  React.useEffect(() => {
    // check if the user is logged in or not
    if (!localStorage.getItem("apiToken")) {
      // get the query params
      const params = new URLSearchParams(window.location.search);
      if (params.get("plansModal") === "true") {
        params.delete("plansModal");
        params.set("redirect", "plans_modal");
      }
      if (invitationId) {
        params.set("invitationId", invitationId);
      }

      window.location.href = `/auth/login${params.toString() !== "" ? "?" + params.toString() : ""}`;
    }
  }, []);

  //The created store
  document.querySelector("body")?.classList.add("ltr", "main-body", "app", "sidebar-mini");
  document.querySelector("body")?.classList.remove("error-page1", "bg-primary");
  const responsiveSidebarclose = () => {
    //leftsidemenu
    if (window.innerWidth < 992) {
      document.querySelector(".app")?.classList.remove("sidenav-toggled");
    }
    //rightsidebar
    document.querySelector(".sidebar-right")?.classList.remove("sidebar-open");
    //swichermainright
    document.querySelector(".demo_changer")?.classList.remove("active");
    // let Rightside: any = document.querySelector(".demo_changer");
    // Rightside.style.right = "-270px";
  };
  function Sidebargone(gone: any) {
    if (gone.matches) {
      // If media query matches
      document.querySelector("body")?.classList.add("sidebar-gone");
    } else {
      document.querySelector("body")?.classList.remove("sidebar-gone");
      document.querySelector("body")?.classList.remove("sidenav-toggled");
    }
  }

  useEffect(() => {
    var gone = window.matchMedia("(max-width: 1024px)");
    Sidebargone(gone); // Call listener function at run time
    gone.addListener(Sidebargone);
  }, []);

  // check if the user is logged in and if is logged in fetch projects and send them to the header component

  const [allProjects, setAllProjects] = useState([]);
  const [allProjectsLoading, setAllProjectsLoading] = useState<boolean>(false);

  const location = useLocation();
  useEffect(() => {
    fetchUserProjects();
  }, [location]);

  const fetchUserProjects = async () => {
    setAllProjectsLoading(true);
    const res: any = await getProjectsForUser(0, 0);
    if (res.status === 200 && res.data.status === "ok") {
      setAllProjects(res.data.projects);
    }
    // TODO: handle error
    setAllProjectsLoading(false);
  };

  useEffect(() => {
    if (window?.innerWidth < 800) {
      openCloseSidebar();
    }
  }, []);

  return (
    <AppContext.Provider value={{ projectCodeArchive, setProjectCodeArchive, projectKey, setProjectKey }}>
      <Fragment>
        <Provider store={Store}>
          <div className="horizontalMenucontainer">
            {/* <TabToTop /> */}
            <div className="page">
              <div className="open">
                <Header
                  openCloseSidebar={openCloseSidebar}
                  isSidebarToggled={isSidebarToggled}
                  allProjects={allProjects}
                />
                <Sidebar openCloseSidebar={openCloseSidebar} />
              </div>

              <div
                className="main-content app-content"
                onClick={() => {
                  responsiveSidebarclose();
                }}
              >
                <div className="side-app">
                  <div className="main-container container-fluid">
                    <Outlet />
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </Provider>
      </Fragment>
    </AppContext.Provider>
  );
}
