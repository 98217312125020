import React from "react";
import Lottie from "react-lottie";
import * as Spinner from "../../../assets/json/GenezioSpinner.json";

const GenezioSpinner = () => {
  const isDarkMode = localStorage.getItem("darkMode") === "true" || localStorage.getItem("darkMode") === null;

  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: Spinner,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      }}
      height={80}
      width={80}
      isClickToPauseDisabled
      style={{
        marginTop: "10px",
        filter: isDarkMode ? "invert(1)" : "none",
      }}
    />
  );
};

export default GenezioSpinner;
