import React from "react";
import { Col } from "react-bootstrap";
import ProgressLine from "./ProgressLine";
import { SidebarProps } from "../../../models/NewProjectFlowModels";
const Sidebar: React.FC<SidebarProps> = ({ repoName, branch, activeStep, basePath }) => {
  return (
    <Col lg={3}>
      <h3 className="fw-bold mb-3">You're almost there!</h3>

      <a
        className="d-flex align-items-center border radius py-2 px-2"
        href={`https://github.com/${repoName}`}
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-github fs-5"></i>
        <p className="mb-0 fw-bold" style={{ marginLeft: "5px" }}>
          {repoName}
        </p>
      </a>
      <div className="d-flex align-items-center mt-3">
        <img
          src={require("../../../assets/img/svgs/branchIcon.svg").default}
          alt="branch"
          className="mx-2"
          style={{ width: "16px" }}
        />
        <p className="mb-0">{branch}</p>
      </div>
      <div className="d-flex align-items-center mt-3">
        <img
          src={require("../../../assets/img/svgs/folderIcon.svg").default}
          alt="branch"
          className="mx-2"
          style={{ width: "16px" }}
        />
        <p className="mb-0">{basePath}</p>
      </div>
      <p className="my-4">Follow these steps to configure and deploy the project to your repository.</p>
      <ProgressLine activeStep={activeStep} />
    </Col>
  );
};
export default Sidebar;
