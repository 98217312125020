import React, { useContext, useEffect, useState } from "react";
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import {
  Col,
  Row,
  Card,
  Button,
  Breadcrumb,
  InputGroup,
  Form,
  Spinner,
  Badge,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { PROJECT_TABLE_COLUMNS } from "../configs/DashboardConfig";
import { getProjectsForUser, deleteProjectById, getUserInformation } from "../network/ApiAxios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import GettingStarted from "./../components/Dashboard/GettingStarted";
import moment from "moment";
import GenezioSpinner from "../components/Utilities/Spinner/GenezioSpinner";
import DeleteProject from "../components/AdvancedUI/Modals/DeleteProject";
import { capitalizeWord } from "./Collaboration/utils";
import { AppContext } from "../shade/layouts/App";

const GlobalFilter = ({ filter, setFilter }: any) => {
  return (
    <Col md={3} className="p-0">
      <span className="d-flex ms-auto ">
        <InputGroup className="mb-3">
          <InputGroup.Text>
            <i className="fa fa-search"></i>
          </InputGroup.Text>
          <Form.Control value={filter || ""} onChange={(e) => setFilter(e.target.value)} placeholder="Search..." />
        </InputGroup>
      </span>
    </Col>
  );
};
export default function Dashboard() {
  const enabledOnDevFlag = process.env.REACT_APP_GITHUB_APP_NAME === "genezio-dev";
  const [projects, setProjects] = useState<any>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [showGettingStartedCards, setShowGettingStartedCards] = useState(false);

  const { setProjectKey } = useContext<any>(AppContext);

  useEffect(() => {
    setProjectKey("");
    if (window.location.pathname.includes("new-project")) {
      setShowGettingStartedCards(true);
      return;
    }

    setShowGettingStartedCards(false);
  }, [window.location.pathname]);

  const [modal, setModal] = useState<boolean>(false);
  const [toBeDeleted, setToBeDeleted] = useState<any>("");

  const pageSize: number = 25;

  const navigation = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [userDetails, setUserDetails] = useState<any>([]);
  const [showWelcome, setShowWelcome] = useState(true);

  const tableInstance = useTable(
    {
      columns: PROJECT_TABLE_COLUMNS,
      data: projects,
      manualPagination: true,
      pageCount,
      initialState: { pageSize },
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    state,
    setGlobalFilter,
    page, // use, page or rows
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
  }: any = tableInstance;

  const { globalFilter, pageIndex } = state;

  React.useEffect(() => {
    if (window.location.pathname.includes("new-project")) document.title = "Genezio | New Project";
    else document.title = "Genezio | Dashboard";
  }, [window.location.pathname]);

  useEffect(() => {
    const runAsync = async () => {
      setIsLoading(true);
      const projectsForUser = await getProjectsForUser(pageIndex * pageSize, pageSize);
      setProjects(projectsForUser.data?.projects);
      const userDetails = await getUserInformation();
      setUserDetails(userDetails?.data?.user);
      const pgCt = Math.ceil(projectsForUser.data.counter / pageSize);
      setPageCount(pgCt);
      setIsLoading(false);
    };

    runAsync();
  }, [pageIndex]);

  async function handleDeleteProject(project: any) {
    const toastId = toast.success("Your project is being deleted, it might take up to 2 minutes", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    project.isDeleting = true;
    const res: any = await deleteProjectById(project.id ?? "");
    if (res.response && res.response.data && res.response.data.error) {
      project.isDeleting = false;
      return;
    }
    if (res.data.status === "ok") {
      setProjects(projects.filter((p: any) => p.id !== project.id));
      toast.dismiss(toastId);
    } else {
      project.isDeleting = false;
    }
  }

  const renderTooltip = (props: any, userRole: string) => (
    <Tooltip id="button-tooltip" {...props}>
      You can't delete this project as {userRole}
    </Tooltip>
  );

  return (
    <React.Fragment>
      {/* {userDetails?.onboardingInfo?.onboardingComplete !== true && !isLoading && (
        <Welcome
          show={showWelcome}
          onHide={async () => {
            setShowWelcome(false);
            const resp: any = await getUserInformation();
            setUserDetails(resp?.data?.user);
          }}
        />
      )} */}

      {/* <DashboardUpdateModal /> */}

      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          {showGettingStartedCards ? (
            <a
              onClick={() => {
                setShowGettingStartedCards((prevValue) => !prevValue);
                navigation(`/dashboard/`, { replace: true });
              }}
              className=""
              style={{ textDecoration: "underline" }}
            >
              {"<< "}All Projects
            </a>
          ) : (
            <>
              {!(projects.length === 0 && !isLoading) ? (
                <Breadcrumb className="breadcrumb">
                  <Breadcrumb.Item
                    className="breadcrumb-item"
                    onClick={() => navigation("/")}
                    aria-current="page"
                    style={{ paddingTop: "1px" }} // hack don;t change it
                  >
                    All Projects
                  </Breadcrumb.Item>
                </Breadcrumb>
              ) : null}
            </>
          )}
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}

      <ToastContainer />

      {/* <!-- Modal --> */}
      <DeleteProject
        projectName={toBeDeleted.name}
        handleDeleteProject={() => handleDeleteProject(toBeDeleted)}
        show={modal}
        onHide={() => setModal(false)}
      />
      {/* <!-- /Modal --> */}
      {/* <!-- row  --> */}

      <Row>
        <Col sm={12} className="col-12">
          <Card>
            {isLoading !== true && !showGettingStartedCards && (
              <>
                {projects.length > 0 && !isLoading ? (
                  <Card.Header>
                    <h4 className="card-title">All Projects</h4>
                  </Card.Header>
                ) : null}
              </>
            )}

            <Card.Body className="pt-0 example1-table">
              {/* <GettingStarted onboardingInfo={userDetails?.onboardingInfo} /> */}
              {projects.length === 0 && isLoading ? (
                <GenezioSpinner />
              ) : showGettingStartedCards || (projects.length === 0 && !isLoading) ? (
                //  && userDetails?.onboardingInfo?.onboardingComplete
                <GettingStarted onboardingInfo={userDetails?.onboardingInfo} />
              ) : (
                <div className="table-responsive">
                  <>
                    <div className="d-flex align-items-center">
                      {projects.length > 0 && !isLoading && (
                        <Col md={9} className="p-0">
                          <button
                            onClick={() => {
                              setShowGettingStartedCards((prevValue) => !prevValue);
                              navigation("/new-project/", { replace: true });
                            }}
                            className="mb-3 btn btn-outline-primary github-new-project-btn-sm "
                          >
                            New Project
                          </button>
                        </Col>
                      )}
                      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                    </div>
                    <table className="table text-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>
                            <span className="tabletitle">#</span>
                          </th>
                          <th>
                            <span className="tabletitle">Project Name</span>
                          </th>
                          {enabledOnDevFlag && (
                            <th>
                              <span className="tabletitle">Cloud Provider</span>
                            </th>
                          )}
                          <th>
                            <span className="tabletitle">Region</span>
                          </th>
                          <th>
                            <span className="tabletitle">Last Edit Date</span>
                          </th>
                          <th>
                            <span className="tabletitle">Created Date</span>
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {page.map((row: any, index: number) => {
                          const elems = row.original;
                          const isOwner = elems.role === "owner" ? true : false;
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <a
                                  style={{
                                    color: "#0d6efd",
                                    textDecoration: "underline",
                                    textDecorationThickness: "1px",
                                  }}
                                  onClick={() => {
                                    const prodProjectEnv = elems.projectEnvs.find((env: any) => env.name === "prod");
                                    if (!prodProjectEnv) {
                                      navigation("/project/" + elems.id + "/" + elems.projectEnvs[0].id + "/");
                                    } else {
                                      navigation("/project/" + elems.id + "/" + prodProjectEnv.id + "/");
                                    }
                                  }}
                                >
                                  {elems.name}
                                </a>
                                {!isOwner && (
                                  <Badge pill bg="" className="mx-2 border text-muted">
                                    {capitalizeWord(elems.role)}
                                  </Badge>
                                )}
                              </td>
                              {enabledOnDevFlag && <td>{elems.cloudProvider}</td>}
                              <td>{elems.region}</td>
                              <td>{moment.unix(elems.updatedAt).fromNow()}</td>
                              <td>{moment.unix(elems.createdAt).format("MMM D, YYYY [at] h:mm A")}</td>
                              <td>
                                {elems.isDeleting ? (
                                  <Spinner animation="border" variant="primary" size="sm" />
                                ) : (
                                  <>
                                    {isOwner ? (
                                      <a
                                        onClick={() => {
                                          setToBeDeleted(elems);
                                          setModal(true);
                                        }}
                                      >
                                        <i className="fa fa-trash" />
                                      </a>
                                    ) : (
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 70, hide: 400 }}
                                        overlay={(e) => renderTooltip(e, elems.role)}
                                      >
                                        <i className="fa fa-trash text-muted" />
                                      </OverlayTrigger>
                                    )}
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {/* {isLoading && (
                      <>
                        <GenezioSpinner />
                      </>
                    )} */}
                    {!isLoading && pageOptions.length === 0 ? (
                      <>
                        <Row>
                          <Col lg={12} style={{ textAlign: "center" }} className="my-3">
                            There are no projects created yet.
                          </Col>
                        </Row>
                      </>
                    ) : null}
                    <div className="d-block d-sm-flex mt-4 ">
                      <span>
                        Page{" "}
                        <strong>
                          {pageIndex + 1} of {pageCount}
                        </strong>{" "}
                      </span>
                      <span className="ms-sm-auto d-flex">
                        <Button
                          variant=""
                          className="btn-default tablebutton me-2 my-1"
                          onClick={() => {
                            gotoPage(0);
                          }}
                          disabled={!canPreviousPage}
                        >
                          {" << "}
                        </Button>
                        <Button
                          variant=""
                          className="btn-default tablebutton me-2 my-1"
                          onClick={() => {
                            previousPage();
                          }}
                          disabled={!canPreviousPage}
                        >
                          {" < "}
                        </Button>
                        <Button
                          variant=""
                          className="btn-default tablebutton me-2 my-1"
                          onClick={() => {
                            nextPage();
                          }}
                          disabled={!canNextPage}
                        >
                          {" > "}
                        </Button>
                        <Button
                          variant=""
                          className="btn-default tablebutton me-2 my-1"
                          onClick={() => {
                            gotoPage(pageCount - 1);
                          }}
                          disabled={!canNextPage}
                        >
                          {" >> "}
                        </Button>
                      </span>
                    </div>
                  </>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
