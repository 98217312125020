import { useEffect, useState } from "react";
import { Button, InputGroup, Form } from "react-bootstrap";
import moment from "moment";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import { useLocation } from "react-router-dom";
import { timelineOptions } from "./utils";

const logLevelOptions = [
  { label: "All", value: "" },
  { label: "Info", value: "info" },
  { label: "Log", value: "log" },
  { label: "Warn", value: "warn" },
  { label: "Error", value: "error" },
];

const requestMethodOptions = [
  { label: "All", value: "" },
  { label: "Get", value: "get" },
  { label: "Post", value: "post" },
  { label: "Head", value: "head" },
  { label: "Put", value: "put" },
  { label: "Delete", value: "delete" },
  { label: "Options", value: "options" },
  { label: "Patch", value: "patch" },
];

type LogsFiltersProps = {
  handleSelectFilters: (key: string, value: any) => void;
  handleResetFilters: () => void;
  activeFilters: any;
  classes: any;
  functions: any;
  isAwsCloud: boolean;
};

const LogsFilters: React.FC<LogsFiltersProps> = ({
  handleSelectFilters,
  activeFilters,
  handleResetFilters,
  classes,
  functions,
  isAwsCloud,
}) => {
  const [menuOpenList, setMenuOpenList] = useState<any>(["timeLine"]);
  const [customTime, setCustomTime] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState<any>({});
  const location = useLocation();

  // Open/Close sidebar filter menus
  const toggleFilterMenu = (newMenu: any) => {
    if (menuOpenList.includes(newMenu)) {
      // Close menu
      setMenuOpenList((prevValue: any) => [...prevValue.filter((menu: any) => menu !== newMenu)]);
    } else {
      // Open menu
      setMenuOpenList((prevValue: any) => [...prevValue, newMenu]);
    }
  };

  const handleTimelineSelect = (e: any) => {
    setSelectedTime(e);

    handleSelectFilters("timeline", e?.label);

    if (e?.value === "custom") {
      setCustomTime(true);
      return;
    }

    setCustomTime(false);
  };

  // useEffect to set default filter values for time
  useEffect(() => {
    const defaultOption: any = timelineOptions.find((option) => option.value === 30 * 60 * 1000);

    const queryParams = new URLSearchParams(location.search);
    const timeline = queryParams.get("timeline") || `${activeFilters.timeline}`;
    const selectedOption = timelineOptions?.find((x: any) => x?.label === timeline);

    if (timeline === "custom") {
      setCustomTime(true);
      setSelectedTime(timelineOptions[timelineOptions.length - 1]);
      return;
    }

    handleTimelineSelect(selectedOption ? selectedOption : defaultOption);
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center my-3">
        <p style={{ fontWeight: "600" }} className="m-0 text-dark">
          Filters
        </p>
        <Button
          onClick={() => {
            handleResetFilters();
            setSelectedTime(timelineOptions[3]);
            setCustomTime(false);
          }}
          className="px-3 env-vars-btn"
          size="sm"
        >
          Reset
        </Button>
      </div>
      {/* Backend ids selector */}
      {/* <InputGroup className="mb-2">
        <Select
          value={[
            ...classes?.map((cls: any) => ({ label: cls.name, value: cls?.id })),
            ...functions?.map((fn: any) => ({ label: fn.name, value: fn?.id })),
          ].filter((option) => activeFilters?.backendIds?.split(",").includes(option.value))}
          onChange={(e) => handleSelectFilters("backendIds", e)}
          options={[
            ...classes?.map((cls: any) => ({ label: cls.name, value: cls?.id })),
            ...functions?.map((fn: any) => ({ label: fn.name, value: fn?.id })),
          ]}
          isMulti={true}
          className="w-100"
        />
      </InputGroup> */}
      {/* Timeline */}
      <div>
        {/* Button to toggle the menu */}
        <div
          onClick={() => toggleFilterMenu("timeLine")}
          style={{ cursor: "pointer" }}
          className="d-flex align-items-center"
        >
          <i
            className={`fa fa-chevron-${menuOpenList.includes("timeLine") ? "down" : "right"} mb-2`}
            aria-hidden="true"
          ></i>
          <p style={{ fontWeight: "600" }} className="mb-2 mx-1">
            Timeline
          </p>
        </div>

        {menuOpenList.includes("timeLine") && (
          <div className="mb-2">
            <InputGroup className="input-group col-xl-12 datetimepicker-2">
              <Select
                defaultValue={timelineOptions.find((option) => option.value === 30 * 60 * 1000)}
                onChange={(e) => handleTimelineSelect(e)}
                options={timelineOptions}
                value={selectedTime}
                className="w-100"
              />
            </InputGroup>
          </div>
        )}
      </div>
      {customTime && (
        <>
          {/* Sart Time */}
          <div>
            {/* Button to toggle the menu */}
            <div
              onClick={() => toggleFilterMenu("startTime")}
              style={{ cursor: "pointer" }}
              className="d-flex align-items-center"
            >
              <i
                className={`fa fa-chevron-${menuOpenList.includes("startTime") ? "down" : "right"} mb-2`}
                aria-hidden="true"
              ></i>
              <p style={{ fontWeight: "600" }} className="mb-2 mx-1">
                Start Time
              </p>
            </div>

            {menuOpenList.includes("startTime") ||
              (activeFilters?.timeline === "Custom" && (
                <div className="mb-2">
                  <InputGroup className="input-group col-xl-12 datetimepicker-2">
                    <Form className="form-control" noValidate>
                      <TextField
                        id="datetime-local"
                        type="datetime-local"
                        value={moment(activeFilters?.startTime).format("YYYY-MM-DDTHH:mm")}
                        onChange={(e) => {
                          handleSelectFilters("startTime", new Date(e.target.value).getTime());
                        }}
                      />
                    </Form>
                  </InputGroup>
                </div>
              ))}
          </div>

          {/* End Time */}
          <div>
            {/* Button to toggle the menu */}
            <div
              onClick={() => toggleFilterMenu("endTime")}
              style={{ cursor: "pointer" }}
              className="d-flex align-items-center"
            >
              <i
                className={`fa fa-chevron-${menuOpenList.includes("endTime") ? "down" : "right"} mb-2`}
                aria-hidden="true"
              ></i>
              <p style={{ fontWeight: "600" }} className="mb-2 mx-1">
                End Time
              </p>
            </div>

            {menuOpenList.includes("endTime") ||
              (activeFilters?.timeline === "Custom" && (
                <div className="mb-2">
                  <InputGroup className="input-group col-xl-12 datetimepicker-2">
                    <Form className="form-control" noValidate>
                      <TextField
                        id="datetime-local"
                        type="datetime-local"
                        value={moment(activeFilters?.endTime).format("YYYY-MM-DDTHH:mm")}
                        onChange={(e) => {
                          handleSelectFilters("endTime", new Date(e.target.value).getTime());
                        }}
                      />
                    </Form>
                  </InputGroup>
                </div>
              ))}
          </div>
        </>
      )}
      {isAwsCloud === false && (
        <>
          {/* Log Level */}
          <div>
            {/* Button to toggle the menu */}
            <div
              onClick={() => toggleFilterMenu("logLevel")}
              style={{ cursor: "pointer" }}
              className="d-flex align-items-center"
            >
              <i
                className={`fa fa-chevron-${menuOpenList.includes("logLevel") ? "down" : "right"} mb-2`}
                aria-hidden="true"
              ></i>
              <p style={{ fontWeight: "600" }} className="mb-2 mx-1">
                Log Level
              </p>
            </div>

            {menuOpenList.includes("logLevel") && (
              <div className="mb-2">
                <InputGroup className="input-group col-xl-12 datetimepicker-2">
                  <Select
                    onChange={(e: any) => handleSelectFilters("logLevel", e.value)}
                    options={logLevelOptions}
                    className="w-100"
                    value={
                      activeFilters?.logLevel
                        ? logLevelOptions.find((option) => option.value === activeFilters.logLevel)
                        : logLevelOptions[0] // Fallback to "All" if no log level is selected
                    }
                  />
                </InputGroup>
              </div>
            )}
          </div>
          {/* Http Method */}
          <div>
            {/* Button to toggle the menu */}
            <div
              onClick={() => toggleFilterMenu("httpMethod")}
              style={{ cursor: "pointer" }}
              className="d-flex align-items-center"
            >
              <i
                className={`fa fa-chevron-${menuOpenList.includes("httpMethod") ? "down" : "right"} mb-2`}
                aria-hidden="true"
              ></i>
              <p style={{ fontWeight: "600" }} className="mb-2 mx-1">
                HTTP Method
              </p>
            </div>

            {menuOpenList.includes("httpMethod") && (
              <div className="mb-2">
                <InputGroup className="input-group col-xl-12 datetimepicker-2">
                  <Select
                    onChange={(e: any) => handleSelectFilters("httpMethod", e.value)}
                    options={requestMethodOptions}
                    className="w-100"
                    value={
                      activeFilters?.httpMethod
                        ? requestMethodOptions.find((option) => option.value === activeFilters.httpMethod)
                        : requestMethodOptions[0] // Fallback to "All" if no method is selected
                    }
                  />
                </InputGroup>
              </div>
            )}
          </div>
          {/* Http Status */}
          <div>
            {/* Button to toggle the menu */}
            <div
              onClick={() => toggleFilterMenu("httpStatus")}
              style={{ cursor: "pointer" }}
              className="d-flex align-items-center"
            >
              <i
                className={`fa fa-chevron-${menuOpenList.includes("httpStatus") ? "down" : "right"} mb-2`}
                aria-hidden="true"
              ></i>
              <p style={{ fontWeight: "600" }} className="mb-2 mx-1">
                HTTP Status
              </p>
            </div>

            {menuOpenList.includes("httpStatus") && (
              <div className="mb-2">
                <InputGroup className="input-group col-xl-12 datetimepicker-2">
                  <Form.Control
                    className="form-control custom-domain-input-box mb-sm-2 mb-0"
                    placeholder="HTTP Status"
                    value={activeFilters?.httpStatus}
                    onChange={(e: any) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value) && value.length <= 3) {
                        handleSelectFilters("httpStatus", value);
                      }
                    }}
                  />
                </InputGroup>
              </div>
            )}
          </div>
          {/* URL */}
          <div>
            {/* Button to toggle the menu */}
            <div
              onClick={() => toggleFilterMenu("url")}
              style={{ cursor: "pointer" }}
              className="d-flex align-items-center"
            >
              <i
                className={`fa fa-chevron-${menuOpenList.includes("url") ? "down" : "right"} mb-2`}
                aria-hidden="true"
              ></i>
              <p style={{ fontWeight: "600" }} className="mb-2 mx-1">
                URL
              </p>
            </div>

            {menuOpenList.includes("url") && (
              <div className="mb-2">
                <InputGroup className="input-group col-xl-12 datetimepicker-2">
                  <Form.Control
                    className="form-control custom-domain-input-box mb-sm-2 mb-0"
                    placeholder="URL"
                    value={activeFilters?.url}
                    onChange={(e: any) => handleSelectFilters("url", e.target.value)}
                  />
                </InputGroup>
              </div>
            )}
          </div>
          {/* operationId */}
          <div>
            {/* Button to toggle the menu */}
            <div
              onClick={() => toggleFilterMenu("operationId")}
              style={{ cursor: "pointer" }}
              className="d-flex align-items-center"
            >
              <i
                className={`fa fa-chevron-${menuOpenList.includes("operationId") || activeFilters.operationId ? "down" : "right"} mb-2`}
                aria-hidden="true"
              ></i>
              <p style={{ fontWeight: "600" }} className="mb-2 mx-1">
                Invocation ID
              </p>
            </div>

            {menuOpenList.includes("operationId") || activeFilters.operationId ? (
              <div className="mb-2 d-flex">
                <InputGroup className="input-group col-xl-12 datetimepicker-2">
                  <Form.Control
                    className="form-control custom-domain-input-box mb-sm-2 mb-0"
                    placeholder="Invocation ID"
                    value={activeFilters?.operationId}
                    onChange={(e: any) => handleSelectFilters("operationId", e.target.value)}
                  />
                  {activeFilters.operationId && (
                    <Button variant="outline-danger" onClick={() => handleSelectFilters("operationId", "")}>
                      <i className="far fa-trash-alt" />
                    </Button>
                  )}
                </InputGroup>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </>
  );
};

export default LogsFilters;
