import { Alert, Col, Button, InputGroup, Form, Row, Spinner } from "react-bootstrap";
import moment from "moment";
import GenezioSpinner from "../../components/Utilities/Spinner/GenezioSpinner";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";

type LogsDataProps = {
  handleSelectFilters: (key: string, value: any) => void;
  handleRefresh: () => void;
  openExportLogsModal: () => void;
  activeFilters: any;
  logsError: any;
  logs: any;
  classOrFunctionInfo: any;
  isLoading: boolean;
  backendIds: any;
  classesAndFunctions: any;
  isAwsCloud: boolean;
};

const MESSAGES_TYPES = ["START", "ALL", "ERROR", "REPORT", "INFO", "DEBUG", "WARNING", "END"];

const LogsData: React.FC<LogsDataProps> = ({
  handleSelectFilters,
  activeFilters,
  handleRefresh,
  isLoading,
  openExportLogsModal,
  logs,
  logsError,
  classOrFunctionInfo,
  backendIds,
  classesAndFunctions,
  isAwsCloud,
}) => {
  const [searchValue, setSearchValue] = useState(null);

  const handleSearchChange = (e: any) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const handleSearchBtn = () => {
    handleSelectFilters("searchTerm", searchValue);
  };

  const columns = [
    { field: "time", headerName: "Time", width: 200, minWidth: 150 },
    {
      field: "message",
      headerName: "Message",
      width: 800,
      minWidth: 400,
      renderCell: (params: any) => {
        const value = params.value || "";
        const formattedMessage = value.split(/(\t|\s|:)/).map((word: any, index: any) => {
          if (MESSAGES_TYPES.includes(word)) {
            return <strong key={index}>{word}</strong>;
          }
          return word;
        });
        return <div>{formattedMessage}</div>;
      },
    },
    { field: "status", headerName: "Status", width: 100, minWidth: 80 },
    { field: "path", headerName: "Path", width: 250, minWidth: 120 },
    {
      field: "invocationId",
      headerName: "Invocation ID",
      width: 250,
      minWidth: 200,
      renderCell: (params: any) => {
        return (
          <p
            className="m-0 text-decoration-underline"
            style={{ cursor: "pointer" }}
            onClick={(e) => handleSelectFilters("operationId", params?.formattedValue)}
          >
            {params?.formattedValue}
          </p>
        );
      },
    },
  ];

  const awsColumns: any = [columns[0], columns[1]];
  const rows = logs.map((elem: any, index: any) => ({
    id: index,
    time: moment.unix(elem.Timestamp / 1000).format("YYYY-MM-DD HH:mm:ss:SSS"),
    status: elem.HttpStatus,
    path:
      elem.HttpMethod +
      " " +
      classesAndFunctions?.find((x: any) => x.cloudDeploymentId === elem?.CloudDeploymentId)?.cloudUrl +
      elem.Url,
    invocationId: elem.OperationId,
    message: elem.Message,
  }));

  return (
    <>
      <div className="mt-4 d-flex align-items-center mb-3 px-2">
        <InputGroup>
          <InputGroup.Text>
            <i className="fa fa-search"></i>
          </InputGroup.Text>
          <Form.Control
            value={searchValue !== null ? searchValue : activeFilters?.searchTerm}
            onChange={handleSearchChange}
            placeholder="Search..."
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSearchBtn();
            }}
            style={{ height: "32px" }}
          />
        </InputGroup>

        <Button
          onClick={handleSearchBtn}
          size="sm"
          className="d-flex align-items-center"
          style={{ marginLeft: "8px" }}
          disabled={isLoading}
        >
          Search
        </Button>

        <Button onClick={() => handleRefresh()} className="mx-2" size="sm" disabled={isLoading}>
          {isLoading ? (
            <Spinner size="sm" variant="" animation="border" />
          ) : (
            <i className="fa fa-refresh" aria-hidden="true"></i>
          )}
        </Button>

        <Button disabled={isLoading} onClick={openExportLogsModal} size="sm" className="d-flex align-items-center">
          Export <i className="fa fa-download mx-1" aria-hidden="true"></i>
        </Button>
      </div>

      {logsError?.response?.data.status === "error" && classOrFunctionInfo?.id && isLoading === false && (
        <Alert className="mx-2" variant="danger">
          {logsError?.response?.data.error.message}
        </Alert>
      )}

      {logs.length !== 0 && backendIds !== "" ? (
        <div className="mx-2 ">
          <DataGrid
            getRowHeight={(params) => "auto"}
            rowSelection={false}
            rows={rows}
            columns={isAwsCloud ? awsColumns : columns}
            hideFooter
            rowCount={logs?.length}
            paginationMode="server"
            autoHeight={true}
            disableColumnSorting={true}
            disableColumnFilter={true}
          />
        </div>
      ) : null}

      {!backendIds ? (
        <Row>
          <Col lg={12} className="my-5 text-center">
            <b>There are no backend selected</b>
          </Col>
        </Row>
      ) : null}

      {!isLoading && !logs.length && backendIds !== "" ? (
        <Row>
          <Col lg={12} className="my-5 text-center">
            <b>There are no logs for the specified filters and period</b>
          </Col>
        </Row>
      ) : null}

      {isLoading && backendIds ? <GenezioSpinner /> : null}
    </>
  );
};

export default LogsData;
