import { useState } from "react";
import { Button, Form, Modal, Alert, Spinner } from "react-bootstrap";
import { createDatabase } from "../../../network/ApiAxios";

type CreateDatabaseModalProps = {
  show: boolean;
  onHide: () => void;
  createDatabaseCallback: (name: string) => void;
};

const NEON_DB_REGIONS = [
  { value: "aws-us-east-1", label: "US East (N. Virginia)" },
  { value: "aws-us-east-2", label: "US East (Ohio)" },
  { value: "aws-us-west-2", label: "US West (Oregon)" },
  { value: "aws-eu-central-1", label: "Europe (Frankfurt)" },
  { value: "aws-ap-southeast-1", label: "Asia Pacific (Singapore)" },
  { value: "aws-ap-southeast-2", label: "Asia Pacific (Sydney)" },
];

const MONGO_DB_REGIONS = [
  { value: "us-east-1", label: "US East (N. Virginia)" },
  { value: "eu-central-1", label: "Europe (Frankfurt)" },
];

function getRegions(dbType: string) {
  switch (dbType) {
    case "postgres-neon":
      return NEON_DB_REGIONS;
    case "mongo-atlas":
      return MONGO_DB_REGIONS;
  }

  return [];
}

const CreateDatabase: React.FC<CreateDatabaseModalProps> = ({ show, onHide, createDatabaseCallback }) => {
  const [dbName, setDbName] = useState<string>("");
  const [dbType, setDbType] = useState<string>("postgres-neon");
  const [dbRegion, setDbRegion] = useState<string>(NEON_DB_REGIONS[0].value);

  const [error, setError] = useState<string>("");

  const [isCreating, setIsCreating] = useState<boolean>(false);

  const handleCloseModal = () => {
    setDbName("");
    setDbType("postgres-neon");
    setError("");
    setDbRegion(NEON_DB_REGIONS[0].value);
    onHide();
  };

  async function handleCreate() {
    setError("");
    setIsCreating(true);
    const resp: any = await createDatabase({ name: dbName, type: dbType, region: dbRegion });
    setIsCreating(false);

    if (resp.response && resp.response.data.error) {
      setError(resp.response.data.error.message);
      return;
    }

    createDatabaseCallback(dbName);
    handleCloseModal();
  }

  return (
    <Modal show={show} onHide={() => handleCloseModal()} className="smaller-modal-width" centered>
      <Modal.Body style={{ padding: "40px" }}>
        <h3 className="mb-3 text-dark " style={{ fontSize: "24px", lineHeight: "22px" }}>
          Create Database
        </h3>
        <hr className="mb-2" style={{ borderColor: "#E5E5E5" }} />
        {/* <p className="mb-2">Some informative text here Some informative text here Some informative text here</p> */}
        {error !== "" && (
          <Alert variant="danger" style={{ color: "#842029" }}>
            {error}
          </Alert>
        )}
        <Form.Group className="mb-3 text-dark">
          <Form.Label className="text-dark">Name</Form.Label>
          <Form.Control
            size="sm"
            className="text-dark"
            type="text"
            placeholder="database name"
            value={dbName}
            onChange={(e) => setDbName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3 text-dark">
          <Form.Label className="text-dark">Type</Form.Label>

          <Form.Select
            size="sm"
            onChange={(e) => {
              setDbType(e.target.value);
              setDbRegion(getRegions(e.target.value)[0].value);
            }}
            value={dbType}
          >
            <option value="postgres-neon">PostgreSQL</option>
            <option value="mongo-atlas">MongoDB</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3 text-dark">
          <Form.Label className="text-dark">Region</Form.Label>

          <Form.Select size="sm" onChange={(e) => setDbRegion(e.target.value)} value={dbRegion}>
            {getRegions(dbType).map((region) => (
              <option key={region.value} value={region.value} selected={region.value === dbRegion}>
                {region.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <hr style={{ borderColor: "#E5E5E5" }} />
        <div className="d-flex justify-content-end">
          <Button className="border rounded py-1 px-4 mx-2 env-vars-btn" size="sm" onClick={() => handleCloseModal()}>
            Close
          </Button>
          <Button
            className="px-4"
            size="sm"
            onClick={() => handleCreate()}
            disabled={isCreating || dbName === "" || dbType === "" || dbRegion === ""}
          >
            Create {isCreating && <Spinner animation="border" size="sm" />}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateDatabase;
