import { useEffect, useState } from "react";
import { Col, Card, Row, Button, InputGroup } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getProjectById, getAllLogs, getLogs } from "../../network/ApiAxios";
import LogsFilters from "./LogsFilters";
import LogsData from "./LogsData";
import LogsExportModal from "../../components/AdvancedUI/Modals/LogsExportModal";
import { useNavigate, useLocation } from "react-router-dom";
import { timelineOptions } from "./utils";
import Select from "react-select";

const DEBOUNCE_DELAY = 300;

const LogsDetails = () => {
  const { projectId, envId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [activeFilters, setActiveFilters] = useState<any>({
    timeline: "",
    startTime: "",
    endTime: "",
    searchTerm: "",
    logLevel: "",
    httpMethod: "",
    httpStatus: "",
    url: "",
    operationId: "",
    backendIds: "",
  });

  const [projectDetails, setProjectDetails] = useState<any>({});
  const [activeEnv, setActiveEnv] = useState<any>({});
  const [classes, setClasses] = useState<any>([]);
  const [functions, setFunctions] = useState<any>([]);
  const [nextToken, setNextToken] = useState<any>(null);
  const [disabledLoadMore, setDisableLoadMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFunction, setIsFunction] = useState<boolean>(false);
  const [classOrFunctionInfo, setClassOrFunctionInfo] = useState<any>({});
  const [logsError, setLogsError] = useState<any>("");
  const [logs, setLogs] = useState<any>([]);
  const [refresh, setRefresh] = useState<number>(0);
  const [exportLogsModal, setExportLogsModal] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    document.title = "Genezio | Logs";
  }, []);

  useEffect(() => {
    const isAwsCloud =
      projectDetails?.cloudProvider === "genezio" || projectDetails?.cloudProvider === "genezio-aws" ? true : false;
    const queryParams = new URLSearchParams(location.search);
    const initialFilters: any = {
      timeline: queryParams.get("timeline") || activeFilters.timeline || "Last 30 minutes",
      startTime:
        parseInt(queryParams.get("startTime") || `${activeFilters.startTime}`) || new Date().getTime() - 30 * 60 * 1000,
      endTime: parseInt(queryParams.get("endTime") || `${activeFilters.endTime}`) || new Date().getTime(),
      searchTerm: queryParams.get("searchTerm") || activeFilters.searchTerm,
      logLevel: queryParams.get("logLevel") || activeFilters.logLevel,
      httpMethod: queryParams.get("httpMethod") || activeFilters.httpMethod,
      httpStatus: queryParams.get("httpStatus") || activeFilters.httpStatus,
      url: queryParams.get("url") || activeFilters.url,
      operationId: queryParams.get("operationId") || activeFilters.operationId,
      backendIds:
        isAwsCloud === false &&
        isFirstRender &&
        queryParams.get("backendIds") === null &&
        activeFilters.backendIds === ""
          ? [
              ...classes.map((cls: any) => ({ label: cls.name, value: cls?.id })),
              ...functions.map((fn: any) => ({ label: fn.name, value: fn?.id })),
            ]
              ?.map((x: any) => x.value)
              ?.join(",")
          : queryParams.get("backendIds") || activeFilters.backendIds,
      // If the user has no filters set by backendIds, set all IDs as default for the first render of the page.
    };

    if ((isFirstRender && classes.length > 0 && activeFilters?.backendIds !== "") || functions.length > 0) {
      setIsFirstRender(false);
    }

    setActiveFilters(initialFilters);
  }, [location.search, classes, functions]);

  // Update URL when filters change
  useEffect(() => {
    const timer = setTimeout(() => {
      const queryParams = new URLSearchParams();
      Object.keys(activeFilters).forEach((key) => {
        const value = (activeFilters as any)[key];
        if (value) {
          queryParams.set(key, value.toString());
        }
      });

      navigate({ search: queryParams.toString() }, { replace: true });
    }, DEBOUNCE_DELAY);

    // Cleanup the timeout if activeFilters changes before the delay finishes
    return () => clearTimeout(timer);
  }, [activeFilters, navigate]);

  // Fetch Project Classes/Functions
  useEffect(() => {
    const runAsync = async () => {
      const projectRes: any = await getProjectById(projectId ?? "");
      const localActiveEnv = projectRes.data.project.projectEnvs.find((env: any) => env.id === envId);

      setProjectDetails(projectRes.data.project);
      setActiveEnv(localActiveEnv);

      setClasses(localActiveEnv.classes);
      setFunctions(localActiveEnv.functions);
    };

    runAsync();
  }, []);

  // Fetch logs
  useEffect(() => {
    const timeNow = new Date().getTime();
    const selectedTimeline: any = timelineOptions?.find((x: any) => x?.label === activeFilters?.timeline);
    const runAsync = async () => {
      setIsLoading(true);
      let response: any;
      if (projectDetails?.cloudProvider === "genezio" || projectDetails?.cloudProvider === "genezio-aws") {
        response = await getLogs(
          activeFilters?.backendIds,
          selectedTimeline && selectedTimeline?.value !== "custom"
            ? timeNow - (selectedTimeline?.value ?? 0)
            : activeFilters?.startTime,
          selectedTimeline && selectedTimeline?.value !== "custom" ? timeNow : activeFilters?.endTime,
          activeFilters?.searchTerm,
          nextToken,
          50,
        );
      } else {
        response = await getAllLogs(
          projectId,
          envId,
          selectedTimeline && selectedTimeline?.value !== "custom"
            ? timeNow - (selectedTimeline?.value ?? 0)
            : activeFilters?.startTime,
          selectedTimeline && selectedTimeline?.value !== "custom" ? timeNow : activeFilters?.endTime,
          activeFilters?.searchTerm,
          activeFilters?.logLevel,
          activeFilters?.httpMethod,
          activeFilters?.httpStatus,
          activeFilters?.url,
          activeFilters?.operationId,
          activeFilters?.backendIds,
          nextToken,
          50,
        );
      }

      if (response?.data?.status === "ok") {
        setLogs(
          response?.data?.logs?.Events?.map((el: any) => {
            return {
              ...el,
              Message: el.Message.replace(/.*?(?=END|ERROR|REPORT|INFO|DEBUG|WARNING)/, ""),
            };
          }),
        );
        setNextToken(response.data.logs.NextToken);
        setLogsError("");
        setIsLoading(false);
      } else {
        setLogs([]);
        setNextToken(null);
        setIsLoading(false);
        setLogsError(response);
      }
    };

    if (
      activeFilters?.startTime &&
      activeFilters?.endTime &&
      projectDetails?.name &&
      activeEnv &&
      activeFilters?.backendIds
    ) {
      runAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, isFunction, refresh, projectDetails, activeEnv]);

  const handleSelectFilters = (key: string, value: any) => {
    setNextToken(null);
    setDisableLoadMore(false);
    setActiveFilters((prevFilters: any) => ({
      ...prevFilters,
      [key]:
        key === "backendIds"
          ? isAwsCloud === false
            ? value?.map((x: any) => x.value)?.join(",")
            : value?.value
          : value,
    }));
  };

  const handleRefresh = () => {
    setNextToken(null);
    setDisableLoadMore(false);
    setRefresh((prevValue: number) => prevValue + 1);
  };

  const handleResetFilters = () => {
    setNextToken(null);
    setDisableLoadMore(false);
    const timeNow = new Date().getTime();
    setActiveFilters({
      startTime: timeNow - 30 * 60 * 1000,
      endTime: timeNow,
      searchTerm: "",
      logLevel: "",
      httpMethod: "",
      httpStatus: "",
      url: "",
      operationId: "",
      backendIds: isAwsCloud
        ? ""
        : [
            ...classes.map((cls: any) => ({ label: cls.name, value: cls?.id })),
            ...functions.map((fn: any) => ({ label: fn.name, value: fn?.id })),
          ]
            ?.map((x: any) => x.value)
            ?.join(","),
    });
  };

  const loadNextLogs = async () => {
    const timeNow = new Date().getTime();
    const selectedTimeline: any = timelineOptions?.find((x: any) => x?.label === activeFilters?.timeline);
    setIsLoading(true);

    let response: any;

    if (projectDetails?.cloudProvider === "genezio" || projectDetails?.cloudProvider === "genezio-aws") {
      console.log("aici ", nextToken);
      response = await getLogs(
        activeFilters?.backendIds,
        selectedTimeline && selectedTimeline?.value !== "custom"
          ? timeNow - (selectedTimeline?.value ?? 0)
          : activeFilters?.startTime,
        selectedTimeline && selectedTimeline?.value !== "custom" ? timeNow : activeFilters?.endTime,
        activeFilters?.searchTerm,
        nextToken,
      );
    } else {
      response = await getAllLogs(
        projectId,
        envId,
        selectedTimeline && selectedTimeline?.value !== "custom"
          ? timeNow - (selectedTimeline?.value ?? 0)
          : activeFilters?.startTime,
        selectedTimeline && selectedTimeline?.value !== "custom" ? timeNow : activeFilters?.endTime,
        activeFilters?.searchTerm,
        activeFilters?.logLevel,
        activeFilters?.httpMethod,
        activeFilters?.httpStatus,
        activeFilters?.url,
        activeFilters?.operationId,
        activeFilters?.backendIds,
        nextToken,
      );
    }

    setLogs(
      logs.concat(
        response?.data?.logs?.Events?.map((el: any) => {
          return {
            ...el,
            Message: el.Message.replace(/.*?(?=END|ERROR|REPORT|INFO|DEBUG|WARNING)/, ""),
          };
        }),
      ),
    );

    if (response?.data?.logs?.Events?.length === 0) {
      setDisableLoadMore(true);
    }
    setNextToken(response.data.logs.NextToken);
    setIsLoading(false);
  };

  const isAwsCloud: boolean =
    projectDetails?.cloudProvider === "genezio" || projectDetails?.cloudProvider === "genezio-aws" ? true : false;

  return (
    <Row className="mt-3">
      <LogsExportModal
        isAwsCloud={
          projectDetails?.cloudProvider === "genezio" || projectDetails?.cloudProvider === "genezio-aws" ? true : false
        }
        projectId={projectId}
        envId={envId}
        show={exportLogsModal}
        onHide={() => setExportLogsModal(false)}
        filters={activeFilters}
        classOrFunctionInfo={classOrFunctionInfo}
        classesAndFunctions={[...classes, ...functions]}
        activeFilters={activeFilters}
      />
      <Col sm={12} className="col-12">
        <Card>
          <Card.Header style={{ borderBottom: "1px solid #E5E5E5" }}>
            <h4 className="card-title">Logs</h4>

            {/* Filter by backendIds */}
            <InputGroup className="mb-2">
              <Select
                value={
                  [
                    ...classes?.map((cls: any) => ({ label: cls.name, value: cls?.id })),
                    ...functions?.map((fn: any) => ({ label: fn.name, value: fn?.id })),
                  ].filter((option) => activeFilters?.backendIds?.split(",")?.includes(option.value)) || []
                }
                onChange={(e) => handleSelectFilters("backendIds", e)}
                options={[
                  ...classes?.map((cls: any) => ({ label: cls.name, value: cls?.id })),
                  ...functions?.map((fn: any) => ({ label: fn.name, value: fn?.id })),
                ]}
                isMulti={isAwsCloud ? false : true}
                className="w-100"
              />
            </InputGroup>
          </Card.Header>
          <Card.Body className="py-0">
            <Row>
              {/* Filters Col */}
              <Col className="logsFilterWrap" md={2}>
                <LogsFilters
                  handleSelectFilters={handleSelectFilters}
                  handleResetFilters={handleResetFilters}
                  activeFilters={activeFilters}
                  classes={classes}
                  functions={functions}
                  isAwsCloud={
                    projectDetails?.cloudProvider === "genezio" || projectDetails?.cloudProvider === "genezio-aws"
                      ? true
                      : false
                  }
                />
              </Col>

              {/* Logs Col */}
              <Col md={10} className="p-0">
                <LogsData
                  isAwsCloud={
                    projectDetails?.cloudProvider === "genezio" || projectDetails?.cloudProvider === "genezio-aws"
                      ? true
                      : false
                  }
                  handleSelectFilters={handleSelectFilters}
                  openExportLogsModal={() => setExportLogsModal(true)}
                  activeFilters={activeFilters}
                  handleRefresh={handleRefresh}
                  logsError={logsError}
                  isLoading={isLoading}
                  classOrFunctionInfo={classOrFunctionInfo}
                  logs={logs}
                  classesAndFunctions={[...classes, ...functions]}
                  backendIds={activeFilters?.backendIds}
                />

                {!isLoading && nextToken !== null && logs.length !== 0 ? (
                  <>
                    {/* Load more button */}
                    <Row>
                      <Col sm={12} className="col-12 my-3 text-center">
                        <Button disabled={disabledLoadMore} variant="primary" onClick={() => loadNextLogs()}>
                          Load More
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : null}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default LogsDetails;
