import { useState } from "react";
import { InputGroup, Button, Modal, Alert, Spinner } from "react-bootstrap";
import Select from "react-select";
import { getAllLogs, getLogs } from "../../../network/ApiAxios";
import { timelineOptions } from "../../../views/Logs/utils";

interface LogsExportModalProps {
  show: boolean;
  onHide: () => void;
  filters: any;
  projectId: any;
  envId: any;
  classOrFunctionInfo: any;
  classesAndFunctions: any;
  activeFilters: any;
  isAwsCloud: boolean;
}

const limitOptions = [
  { label: "1000", value: 1000 },
  { label: "5000", value: 5000 },
  { label: "10000", value: 10000 },
];

const LogsExportModal: React.FC<LogsExportModalProps> = ({
  show,
  onHide,
  filters,
  projectId,
  envId,
  classesAndFunctions,
  activeFilters,
  isAwsCloud,
}) => {
  const [selectedLimit, setSelectedLimit] = useState<any>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const closeModal = () => {
    onHide();
    setSelectedLimit(0);
  };

  const handleExport = async () => {
    setIsLoading(true);
    const timeNow = new Date().getTime();
    const selectedTimeline: any = timelineOptions?.find((x: any) => x?.label === activeFilters?.timeline);

    let response: any;
    if (isAwsCloud) {
      response = await getLogs(
        activeFilters?.backendIds,
        selectedTimeline && selectedTimeline?.value !== "custom"
          ? timeNow - (selectedTimeline?.value ?? 0)
          : activeFilters?.startTime,
        selectedTimeline && selectedTimeline?.value !== "custom" ? timeNow : activeFilters?.endTime,
        activeFilters?.searchTerm,
        "",
        selectedLimit,
      );
    } else {
      response = await getAllLogs(
        projectId,
        envId,
        selectedTimeline && selectedTimeline?.value !== "custom"
          ? timeNow - (selectedTimeline?.value ?? 0)
          : activeFilters?.startTime,
        selectedTimeline && selectedTimeline?.value !== "custom" ? timeNow : activeFilters?.endTime,
        filters?.searchTerm,
        filters?.logLevel,
        filters?.httpMethod,
        filters?.httpStatus,
        filters?.url,
        filters?.operationId,
        filters?.backendIds,
        "",
        selectedLimit,
      );
    }

    if (response?.data?.status === "ok") {
      // Handle CSV export
      const logs = response?.data?.logs?.Events;
      await downloadCSV(logs);
    } else {
      closeModal();
    }
    setIsLoading(false);
  };

  const convertToCSV = (logs: any) => {
    if (logs.length === 0) return "";

    // Define the CSV headers
    const headers = ["Time", "Message", "Status", "Path", "Request", "Operation ID"];
    const awsHeaders = ["Time", "Message"];

    // Convert headers to CSV format
    const headerString = isAwsCloud ? awsHeaders : headers.join(",");

    // Convert each log entry to a CSV row
    const rows = logs.map((log: any) => {
      const time = new Date(log.Timestamp).toLocaleString(); // Convert timestamp to readable date-time

      const path =
        log.HttpMethod +
        " " +
        classesAndFunctions?.find((x: any) => x.cloudDeploymentId === log?.CloudDeploymentId)?.cloudUrl +
        log.Url;

      const status = log.HttpStatus || "";
      const host = path;
      // const request = log.HttpMethod || "";
      const operationId = log.OperationId || "";

      // Format message, if needed (you can include additional formatting here)
      const message = (log.Message || "")
        .replace(/"/g, '""') // Escape double quotes
        .replace(/\n/g, " "); // Replace new lines with spaces

      return isAwsCloud
        ? [time, message].map((field) => `"${field}"`).join(",")
        : [time, message, status, host, operationId].map((field) => `"${field}"`).join(",");
    });

    // Combine header and rows into one CSV string
    return [headerString, ...rows].join("\n");
  };

  const downloadCSV = (logs: any) => {
    const csv = convertToCSV(logs);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", "logs.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    closeModal();
  };

  return (
    <Modal show={show} onHide={() => closeModal()} className="smaller-modal-width" centered>
      <Modal.Body style={{ padding: "40px" }}>
        <h3 className="mb-3 text-dark " style={{ fontSize: "24px", lineHeight: "22px" }}>
          Export Logs (CSV)
        </h3>
        <hr className="mb-2" style={{ borderColor: "#E5E5E5" }} />
        {!filters?.backendIds && (
          <Alert variant="danger">
            <b>There are no backend selected in your logs filters</b>
          </Alert>
        )}
        <p className="mb-1 fw-bold">Result Limit</p>
        <InputGroup className="input-group ">
          <Select onChange={(e: any) => setSelectedLimit(e.value)} options={limitOptions} className="w-100" />
        </InputGroup>
        <div className="d-flex justify-content-end mt-3">
          <Button className="border rounded py-1 px-4 mx-2 env-vars-btn" size="sm" onClick={() => closeModal()}>
            Close
          </Button>
          <Button
            variant="primary"
            className="px-4"
            size="sm"
            disabled={!selectedLimit || !filters?.backendIds || isLoading}
            onClick={() => handleExport()}
          >
            Export {isLoading && <Spinner variant="" className="mx-1" animation="border" size="sm" />}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LogsExportModal;
