import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Row, Card, Breadcrumb, Spinner, Tabs, Tab } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import useProjectDetails from "../../hooks/useProjectDetails";
import MethodsTab from "./MethodsTab";
import SettingsTab from "./SettingsTab";
import ActivateAuthModal from "../../components/AdvancedUI/Modals/ActivateAuthModal";
import DisableAuthModal from "../../components/AdvancedUI/Modals/DisableAuthModal";
import EnableAuthProviderModal from "../../components/AdvancedUI/Modals/EnableAuthProviderModal";
import CreateDatabase from "../../components/AdvancedUI/Modals/CreateDatabase";
import { getAuthProviders, getAuth, getAllDatabases } from "../../network/ApiAxios";
import { CopyButton } from "@genezio/test-interface-component";
import { sendToast } from "../Integrations/utils";

const tabs = ["Sign-in methods", "Settings"];

const ProjectAuthentication = () => {
  const darkMode = localStorage.getItem("darkMode") === "true";
  const { projectId, envId } = useParams<{ projectId?: string; envId?: string }>();
  const { project, activeEnv } = useProjectDetails(projectId || "", envId || "");

  const [activeTab, setActiveTab] = useState("Sign-in methods");
  const [activateAuthModal, setActivateAuthModal] = useState(true);
  const [disableAuthModal, setDisableAuthModal] = useState(false);
  const [authProvidersLoading, setAuthProvidersLoading] = useState<boolean>(false);
  const [authProviders, setAuthProviders] = useState([]);
  const [authLoading, setAuthLoading] = useState<boolean>(false);
  const [auth, setAuth] = useState<any>();
  const [selectedProvider, setSelectedProvider] = useState<any>({});
  const [providerModalShow, setProviderModalShow] = useState<boolean>(false);
  const [databases, setDatabases] = useState<any>([]);
  const [createDatabaseModal, setCreateDatabaseModal] = useState<boolean>(false);
  const [selectedDbValue, setSelectedDbValue] = useState<any>();
  const [GeneralSettingsSelectedDbValue, setGeneralSettingsSelectedDbValue] = useState<any>({});

  const navigate = useNavigate();

  const fetchAuthProviders = async () => {
    if (envId) {
      setAuthProvidersLoading(true);
      const res: any = await getAuthProviders(envId);
      if (res.status === 200 && res.data.status === "ok") {
        setAuthProviders(res.data.authProviders);
      } else {
        sendToast(res.response.data.error.message, "error");
      }
      setAuthProvidersLoading(false);
    }
  };

  const fetchAuth = async () => {
    if (envId) {
      setAuthLoading(true);
      const res: any = await getAuth(envId);
      if (res.status === 200) {
        setAuth(res.data);
      } else {
        sendToast(res.response.data.error.message, "error");
      }
      setAuthLoading(false);
    }
  };

  const fetchDatabases = async () => {
    const res: any = await getAllDatabases();
    if (res.status === 200 && res.data.status === "ok") {
      setDatabases(res.data.databases);
    } else {
      sendToast(res.response.data.error.message, "error");
    }
  };

  useEffect(() => {
    fetchAuthProviders();
    fetchAuth();
    fetchDatabases();
  }, []);

  useEffect(() => {
    document.title = "Genezio | Authentication";
  }, []);

  return (
    <>
      <ToastContainer />

      {/* Create database modal */}
      <CreateDatabase
        show={createDatabaseModal}
        onHide={() => setCreateDatabaseModal(false)}
        createDatabaseCallback={async (dbName) => {
          fetchDatabases();
          const localDbs: any = await getAllDatabases();
          const createdDb = localDbs?.data?.databases?.find((db: any) => db.name === dbName);

          setSelectedDbValue({
            label: `${createdDb?.name} - ${createdDb?.region}`,
            value: createdDb?.id,
          });
          setGeneralSettingsSelectedDbValue({
            label: `${createdDb?.name} - ${createdDb?.region}`,
            value: createdDb?.id,
          });

          setCreateDatabaseModal(false);
        }}
      />

      {/* Activate auth modal */}
      {!authLoading && auth && activateAuthModal && !auth.enabled && (
        <ActivateAuthModal
          disabledAuthFeature={false} // (We used this to disable authentication for projects that had no classes)
          show={true}
          projectId={projectId}
          envId={envId}
          hide={() => setActivateAuthModal(false)}
          fetchAuth={() => fetchAuth()}
          selectedDbValue={selectedDbValue}
          setSelectedDbValue={(e) => setSelectedDbValue(e)}
          databases={databases}
          toggleDatabaseModal={() => setCreateDatabaseModal(!createDatabaseModal)}
        />
      )}

      {/* Disable auth modal */}
      <DisableAuthModal
        envId={envId}
        hide={() => setDisableAuthModal(false)}
        onDisableSuccess={() => {
          setDisableAuthModal(false);
          navigate(`/project/${projectId}/${envId}/backend`);
          fetchAuth();
        }}
        show={disableAuthModal}
      />
      {/* Enable auth provider modal */}
      {selectedProvider && (
        <EnableAuthProviderModal
          handleCancel={() => setProviderModalShow(false)}
          fetchAuthProviders={() => fetchAuthProviders()}
          envId={envId}
          show={providerModalShow}
          providerData={selectedProvider}
        />
      )}

      <Row className="mt-3">
        <Col sm={12}>
          <Card>
            <Card.Body className="pt-0">
              <Row className="pt-3 pb-1">
                <Col md={5} className="d-flex justify-content-between align-items-center">
                  <Card.Header className="pb-0">
                    <h5 className="card-title" style={{ textTransform: "none" }}>
                      Authentication
                    </h5>
                  </Card.Header>
                </Col>
              </Row>

              <Card.Body className="pt-0">
                <Row className="mb-4">
                  {authLoading ? (
                    <div className="d-flex w-100 justify-content-center p-2">
                      <Spinner variant="primary" animation="border" />
                    </div>
                  ) : (
                    <Col sm={12} className="col-12 p-0">
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={activeTab}
                        onSelect={(tab: any) => setActiveTab(tab)}
                      >
                        {tabs.map((tab: string) => {
                          const tabTitle = tab.charAt(0).toUpperCase() + tab.slice(1);
                          return (
                            <Tab
                              key={tab}
                              eventKey={tab}
                              title={tabTitle}
                              active={activeTab === tab}
                              disabled={activeTab === tab}
                            />
                          );
                        })}
                      </Tabs>

                      <p className="fw-medium text-muted mt-3 mx-4">
                        Enable the authentication methods you wish to use.
                      </p>

                      <p className="fw-medium text-muted mt-3 mx-4">
                        Use the "token" and "region" values to initialize the authentication SDK.
                      </p>
                      {auth?.token && (
                        <div className="mx-4 mb-3 d-flex align-items-center">
                          <p className="text-muted mb-0">
                            Token: <span className={darkMode ? "text-white" : "text-black"}>{auth?.token}</span>
                          </p>
                          <CopyButton text={auth?.token} />
                        </div>
                      )}

                      {auth?.region && (
                        <div className="mx-4 pb-3 mb-4 d-flex align-items-center border-bottom">
                          <p className="text-muted m-0">
                            Region: <span className={darkMode ? "text-white" : "text-black"}>{auth?.region}</span>
                          </p>
                          <CopyButton text={auth?.region} />
                        </div>
                      )}

                      {/* Tabs */}
                      {authProvidersLoading ? (
                        <div className="d-flex justify-content-center p-2">
                          <Spinner variant="primary" animation="border" />
                        </div>
                      ) : activeTab === "Sign-in methods" ? (
                        <MethodsTab
                          handleSelectProvider={(provider: any) => {
                            setSelectedProvider(provider);
                            setProviderModalShow(true);
                          }}
                          authProviders={authProviders}
                        />
                      ) : (
                        <SettingsTab
                          handleDisableAuth={() => setDisableAuthModal(true)}
                          toggleDatabaseModal={() => setCreateDatabaseModal(!createDatabaseModal)}
                          envId={envId}
                          generalSettingsData={auth}
                          databases={databases}
                          GeneralSettingsSelectedDbValue={GeneralSettingsSelectedDbValue}
                          setGeneralSettingsSelectedDbValue={(e) => setGeneralSettingsSelectedDbValue(e)}
                          fetchAuth={() => fetchAuth()}
                        />
                      )}
                    </Col>
                  )}
                </Row>
              </Card.Body>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ProjectAuthentication;
