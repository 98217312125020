import React from "react";
import { Col, Form, Row, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from "@react-oauth/google";
import { AuthComponent } from "../../components/Signup/AuthComponent";

const SignIn = () => {
  return (
    <React.Fragment>
      <div className="page bg-white">
        <div className="page-single ">
          <div className="container" style={{ marginTop: "20vh" }}>
            <Row>
              <Col
                xl={5}
                lg={6}
                md={8}
                sm={8}
                xs={10}
                className="card-sigin-main mx-auto my-auto py-4 justify-content-center"
              >
                <div className="card-sigin" style={{ border: "3px solid #6f42c1" }}>
                  {/* <!-- Demo content--> */}
                  <div className="main-card-signin d-md-flex">
                    <div className="wd-100p">
                      <div className="d-flex mb-4 justify-content-center">
                        <img
                          src={require("../../assets/img/brand/icon-black.svg").default}
                          className="sign-favicon ht-40 invert-dark-theme"
                          alt="logo"
                        />
                      </div>
                      <div className="">
                        <div className="main-signup-header">
                          <h2 className="text-center mb-4 pb-3 text-dark-theme" style={{ fontSize: 40 }}>
                            Sign In
                          </h2>
                          <div className="panel panel-primary">
                            <div className=" tab-menu-heading mb-2 border-bottom-0">
                              <div className="tabs-menu1">
                                <AuthComponent type="signin" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

SignIn.propTypes = {};

SignIn.defaultProps = {};

export default SignIn;
