import { useState, useEffect } from "react";
import { Spinner, Row, Col, Button, Form, Accordion } from "react-bootstrap";
import InfoTooltip from "../../../components/Tooltips/InfoTooltip";
// import ProjectFolderModal from "./ProjectFolderModal";
import { parseEnv, FrameworkSettings, mapFrameworkToSettings, frameworks } from "../utils";
import { getProjectsForUser } from "../../../network/ApiAxios";
import { ProjectConfigurationProps } from "../../../models/NewProjectFlowModels";
import ProjectFolderModal from "./ProjectFolderModal";
const ProjectConfiguration: React.FC<ProjectConfigurationProps> = ({
  githubRepositoryId,
  handleDeployProject,
  projectConfiguration,
  frameworkProps,
  isForkFlow,
  githubAccountName,
  repoName,
  githubUserRepos,
  basePath,
  envVarsKeys,
  handleChangeBasePath,
}) => {
  const [isDeployingLoading, setIsDeployingLoading] = useState<boolean>(false);
  const [showRootDirectoryModal, setShowRootDirectoryModal] = useState<boolean>(false);
  const [selectedPath, setSelectedPath] = useState<string>(basePath || "./");
  const [framework, setFramework] = useState<string>(frameworkProps);
  const [projectConfigurationInput, setProjectConfigurationInput] = useState<any>(projectConfiguration || {});
  const [allProjects, setAllProjects] = useState<any>([]);
  const [repositoryName, setRepositoryName] = useState<string>(repoName || "");
  const [privateRepo, setPrivateRepo] = useState<boolean>(false);
  const [frameworkSettings, setFrameworkSettings] = useState<FrameworkSettings>(
    mapFrameworkToSettings(framework || "other"),
  );
  const [attemptedDeploy, setAttemptedDeploy] = useState<boolean>(false);
  // env
  const [env, setEnv] = useState<any>([]);
  const [envInput, setEnvInput] = useState<any>({
    name: "",
    value: "",
  });
  // functions
  const [functions, setFunctions] = useState<any>([]);
  const [functionsInput, setFunctionsInput] = useState<any>({
    type: "",
    name: "",
  });
  const handleAddFunction = () => {
    setFunctions((prevValue: any) => [...prevValue, functionsInput]);
    setFunctionsInput({
      type: "",
      name: "",
    });
  };
  const handleAddEnv = () => {
    const formatedEnv = parseEnv(envInput.name + "=" + envInput.value);
    setEnv((prevValue: any) => [...prevValue, ...formatedEnv]);
    setEnvInput({
      name: "",
      value: "",
    });
  };
  const handlePasteEnv = (event: any) => {
    const data = event.clipboardData.getData("text");
    if (!data.includes("=")) {
      return;
    }
    const parsedData = parseEnv(data);
    event.preventDefault();
    const updatedFileContent = env.filter((obj: any) => Object.keys(obj).length > 0);
    setEnv([...updatedFileContent, ...parsedData]);
    setEnvInput({
      name: "",
      value: "",
    });
  };
  const handleRemoveEnv = (indexToRemove: any) => {
    setEnv((prevEnv: any) => prevEnv.filter((_: any, idx: any) => idx !== indexToRemove));
  };
  const handlePathSelect = (path: string) => {
    handleChangeBasePath && handleChangeBasePath(path);
  };
  useEffect(() => {
    async function fetchProjects() {
      const resProjects = await getProjectsForUser(0, 1000);
      setAllProjects(resProjects.data?.projects);
    }
    fetchProjects();
    if (envVarsKeys && envVarsKeys.length > 0) {
      envVarsKeys.forEach((key) => {
        setEnv((prevValue: any) => [...prevValue, { name: key, value: "" }]);
      });
    }
  }, []);
  return (
    <>
      <ProjectFolderModal
        githubRepositoryId={githubRepositoryId}
        onHide={() => setShowRootDirectoryModal(false)}
        onSelect={handlePathSelect}
        show={showRootDirectoryModal}
        selectedPath={selectedPath}
      />

      <Col lg={1}></Col>

      <Col className="border rounded p-4" lg={8}>
        {isForkFlow && (
          <div className="mb-4">
            {/* Project info */}
            <h4 className="m-0 mb-2 fw-bold">Create GitHub repository</h4>
            <p>
              To ensure seamless updates to your project after deployment, a GitHub repository must be created. Each
              push to this GitHub repository will trigger an automatic deployment.
            </p>
            <Form.Group className="mb-3">
              <Form.Label className="mb-2 mt-3">GitHub account</Form.Label>
              <Form.Control value={githubAccountName} disabled />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="mb-2 mt-3">Repository name</Form.Label>
              <Form.Control
                value={repositoryName}
                onChange={(e) => {
                  setRepositoryName(e.target.value);
                }}
              />
              <div className={`invalid-feedback ${!repositoryName && attemptedDeploy ? "d-block" : ""}`}>
                Repository name can't be empty.
              </div>
              <div
                className={`invalid-feedback ${
                  githubUserRepos && githubUserRepos.find((r: any) => r.name === repositoryName) ? "d-block" : ""
                }`}
              >
                Repository with this name already exists.
              </div>
            </Form.Group>
            <Form.Group className="mb-3 text-dark">
              <Form.Check
                type="checkbox"
                label="Create private Git Repository"
                name="visibility"
                id="public"
                checked={privateRepo}
                onChange={(e) => setPrivateRepo(e.target.checked)}
              />
            </Form.Group>
          </div>
        )}
        {/* Project info */}
        <h4 className="m-0 fw-bold">Set up your project</h4>
        <Form.Group className="mb-3">
          <Form.Label className="mb-2 mt-3">Project Name</Form.Label>
          <Form.Control
            onChange={(e) => {
              setProjectConfigurationInput((prevValue: any) => ({ ...prevValue, name: e.target.value }));
            }}
            value={projectConfigurationInput.name}
            name="subject"
            placeholder="Project name"
          />
          <div
            className={`invalid-feedback ${
              !/^[a-zA-Z][-a-zA-Z0-9]*$/.test(projectConfigurationInput.name) && attemptedDeploy ? "d-block" : ""
            }`}
          >
            Project name must start with a letter and can contain letters, numbers, and hyphens. No spaces or special
            characters are allowed.
          </div>
          <div
            className={`invalid-feedback ${
              allProjects.find((p: any) => p.name === projectConfigurationInput.name) ? "d-block" : ""
            }`}
          >
            Project name already exists.
          </div>
        </Form.Group>
        {projectConfigurationInput.region && (
          <Form.Group className="mb-3 text-dark">
            <Form.Label className="mb-2 mt-3">Project Region:</Form.Label>
            <Form.Select
              style={{ color: "#4a4a69", height: "40px" }}
              onChange={(e: any) => {
                setProjectConfigurationInput((prevValue: any) => ({ ...prevValue, region: e.target.value }));
              }}
              value={projectConfigurationInput.region}
            >
              <option value="us-east-1">US East (N. Virginia)</option>
              <option value="eu-central-1">Europe (Frankfurt) </option>
            </Form.Select>
          </Form.Group>
        )}
        {/* If no framework, then it is a project with genezio.yaml and the config is not needed */}
        {githubRepositoryId !== 0 && (
          <>
            <Form.Label className="mb-2 mt-3 d-flex align-items-center">
              Project Folder
              <InfoTooltip>Path to the project folder in your repository.</InfoTooltip>
            </Form.Label>
            <Row className="d-flex align-items-start">
              <Col lg={10}>
                <Form.Group className="mb-3">
                  <Form.Control
                    value={selectedPath || "./"}
                    name="path"
                    placeholder="./"
                    onChange={(e) => {
                      if (e.target.value.startsWith("/")) {
                        e.target.value = e.target.value.slice(1);
                      }
                      setSelectedPath(e.target.value);
                    }}
                    disabled={basePath ? true : false}
                  />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <button onClick={() => setShowRootDirectoryModal(true)} className="btn btn-outline-primary w-100">
                  Change
                </button>
              </Col>
            </Row>
          </>
        )}
        {framework ? (
          <>
            <Form.Group className="mb-3 text-dark">
              <Form.Label className="mb-2 mt-3">Framework Preset</Form.Label>
              <Form.Select
                style={{ color: "#4a4a69", height: "40px" }}
                onChange={(e: any) => {
                  setFramework(e.target.value);
                  setFrameworkSettings(mapFrameworkToSettings(e.target.value));
                }}
                value={framework}
              >
                {frameworks.map((f) => (
                  <option key={f.slug} value={f.slug} selected={f.slug === framework}>
                    {f.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            {/* Build Settings */}
            {!["next"].includes(framework) && (
              <Accordion className="border rounded p-3 mb-3" defaultActiveKey={["0"]} alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="mb-2">
                    Build Settings
                    <InfoTooltip>You can modify this later in the genezio.yaml file.</InfoTooltip>
                  </Accordion.Header>
                  <Accordion.Body className="p-0 ">
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-2 mt-3">Build Command</Form.Label>
                      <Form.Control
                        placeholder="Build Command"
                        value={frameworkSettings.buildCommand}
                        onChange={(e) => {
                          setFrameworkSettings((prevValue: any) => ({ ...prevValue, buildCommand: e.target.value }));
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-2 mt-3">Output Directory</Form.Label>
                      <Form.Control
                        placeholder="Output Directory"
                        value={frameworkSettings.buildOutput}
                        onChange={(e) => {
                          setFrameworkSettings((prevValue: any) => ({ ...prevValue, buildOutput: e.target.value }));
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-2 mt-3">Install Command</Form.Label>
                      <Form.Control
                        placeholder="Install Command"
                        value={frameworkSettings.installCommand}
                        onChange={(e) => {
                          setFrameworkSettings((prevValue: any) => ({ ...prevValue, installCommand: e.target.value }));
                        }}
                      />
                    </Form.Group>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
            {/* Functions */}
            {/* <Accordion className="border rounded p-3 mb-3" defaultActiveKey={["0"]} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="mb-2">Functions</Accordion.Header>
                <Accordion.Body className="p-0 ">
                  {functions.length > 0 && (
                    <Accordion className="border rounded p-3 mb-3" defaultActiveKey={["0"]} alwaysOpen>
                      {functions.map((func: any, idx: any) => (
                        <Accordion.Item eventKey={idx}>
                          <Accordion.Header className="mb-2">{idx + 1 + " - " + func.name}</Accordion.Header>
                          <Accordion.Body className="p-0 ">
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-2 mt-3">Type</Form.Label>
                              <Form.Control
                                onChange={(e) => {
                                  setFunctions((prevValue: any) =>
                                    prevValue.map((item: any, index: any) =>
                                      idx === index ? { ...item, type: e.target.value } : item,
                                    ),
                                  );
                                }}
                                value={func.type}
                                name="subject"
                                placeholder="Type"
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-2 mt-3">Name</Form.Label>
                              <Form.Control
                                onChange={(e) => {
                                  setFunctions((prevValue: any) =>
                                    prevValue.map((item: any, index: any) =>
                                      idx === index ? { ...item, name: e.target.value } : item,
                                    ),
                                  );
                                }}
                                value={func.name}
                                name="subject"
                                placeholder="Name"
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-2 mt-3">Path</Form.Label>
                              <Form.Control
                                onChange={(e) => {
                                  setFunctions((prevValue: any) =>
                                    prevValue.map((item: any, index: any) =>
                                      idx === index ? { ...item, path: e.target.value } : item,
                                    ),
                                  );
                                }}
                                value={func.path}
                                name="subject"
                                placeholder="Path"
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-2 mt-3">Handler</Form.Label>
                              <div className="position-relative">
                                <SyntaxHighlighter
                                  customStyle={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                  className="p-4 rounded"
                                  language={"javascript"}
                                  // style={darkMode ? atomOneDark : docco}
                                >
                                  {handlerText}
                                </SyntaxHighlighter>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    right: "10px",
                                    top: "10px",
                                    width: "100%",
                                    maxWidth: "20px",
                                  }}
                                  onClick={() => navigator.clipboard.writeText(handlerText)}
                                >
                                  <img
                                    src={require("./../../../assets/img/svgs/copy_icon.svg").default}
                                    alt="Copy"
                                    style={{ width: "120%", maxWidth: "120%" }}
                                  />
                                </div>
                              </div>
                            </Form.Group>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-2 mt-3">Type</Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        setFunctionsInput((prevValue: any) => ({ ...prevValue, type: e.target.value }));
                      }}
                      value={functionsInput.type}
                      name="subject"
                      placeholder="Type"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-2 mt-3">Name</Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        setFunctionsInput((prevValue: any) => ({ ...prevValue, name: e.target.value }));
                      }}
                      value={functionsInput.name}
                      name="subject"
                      placeholder="Name"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-2 mt-3">Path</Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        setFunctionsInput((prevValue: any) => ({ ...prevValue, path: e.target.value }));
                      }}
                      value={functionsInput.path}
                      name="subject"
                      placeholder="Path"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-2 mt-3">Handler</Form.Label>
                    <div className="position-relative">
                      <SyntaxHighlighter
                        customStyle={{
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                        className="p-4 rounded"
                        language={"javascript"}
                        // style={darkMode ? atomOneDark : docco}
                      >
                        {handlerText}
                      </SyntaxHighlighter>
                      <div
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: "10px",
                          top: "10px",
                          width: "100%",
                          maxWidth: "20px",
                        }}
                        onClick={() => navigator.clipboard.writeText(handlerText)}
                      >
                        <img
                          src={require("./../../../assets/img/svgs/copy_icon.svg").default}
                          alt="Copy"
                          style={{ width: "120%", maxWidth: "120%" }}
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <div className="d-flex justify-content-end">
                    <Button
                      disabled={functionsInput.type && functionsInput.name && functionsInput.path ? false : true}
                      onClick={() => handleAddFunction()}
                      className="px-4"
                    >
                      Add a new function
                    </Button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
          </>
        ) : (
          <>
            <p>
              We detected a genezio.yaml file in the repository and prefilled the project configuration for you. You can
              modify this later in the genezio.yaml file.
            </p>
          </>
        )}
        {((envVarsKeys && envVarsKeys.length > 0) || framework) && (
          <>
            {/* Environment Variables */}
            <Accordion className="border rounded p-3 mb-3" defaultActiveKey={["0"]} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="mb-2">
                  Environment Variables
                  <InfoTooltip>
                    You can change this later on in your project dashboard under the Environment Variables section.
                  </InfoTooltip>
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <Row className="d-flex align-items-end mb-3">
                    <Col lg={5}>
                      <Form.Group className="w-100">
                        <Form.Label className="mb-2 mt-3">Key</Form.Label>
                        <Form.Control
                          onChange={(e) => {
                            setEnvInput((prevValue: any) => ({ name: e.target.value, value: prevValue.value }));
                          }}
                          onPaste={(e) => handlePasteEnv(e)}
                          value={envInput.name}
                          name="name"
                          placeholder="EXAMPLE_NAME"
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={5} className="d-flex">
                      <Form.Group className="w-100">
                        <Form.Label className="mb-2 mt-3">Value</Form.Label>
                        <Form.Control
                          onChange={(e) => {
                            setEnvInput((prevValue: any) => ({ name: prevValue.name, value: e.target.value }));
                          }}
                          value={envInput.value}
                          name="value"
                          placeholder="EXAMPLE_VALUE"
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={2}>
                      <button
                        disabled={envInput.name && envInput.value ? false : true}
                        onClick={() => handleAddEnv()}
                        className="btn btn-outline-primary w-100"
                      >
                        Add
                      </button>
                    </Col>
                  </Row>
                  <p>
                    <b>TIP:</b> Paste .env content above to automatically fill in the form
                  </p>
                  {/* Map added env */}
                  {env?.map((currentEnv: any, index: any) => (
                    <Row key={index} className="d-flex align-items-start mb-3">
                      <Col lg={5}>
                        <Form.Group className="w-100">
                          <Form.Control
                            onChange={(e) => {
                              setEnv((prevEnv: any) =>
                                prevEnv.map((item: any, idx: any) =>
                                  idx === index ? { ...item, name: e.target.value } : item,
                                ),
                              );
                            }}
                            value={currentEnv.name}
                            name="name"
                            placeholder="EXAMPLE_NAME"
                          />
                          <div className={`invalid-feedback ${!currentEnv.name && attemptedDeploy ? "d-block" : ""}`}>
                            Key can't be empty.
                          </div>
                          <div
                            className={`invalid-feedback ${
                              currentEnv.name.length > 0 && currentEnv.name.length < 2 && attemptedDeploy
                                ? "d-block"
                                : ""
                            }`}
                          >
                            Key be at least 2 characters{" "}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={5} className="d-flex">
                        <Form.Group className="w-100">
                          <Form.Control
                            onChange={(e) => {
                              setEnv((prevEnv: any) =>
                                prevEnv.map((item: any, idx: any) =>
                                  idx === index ? { ...item, value: e.target.value } : item,
                                ),
                              );
                            }}
                            value={currentEnv.value}
                            name="value"
                            placeholder="EXAMPLE_VALUE"
                          />
                          <div className={`invalid-feedback ${!currentEnv.value && attemptedDeploy ? "d-block" : ""}`}>
                            Value can't be empty.
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={2} className="d-flex justify-content-center">
                        <Button variant="outline-danger" className="w-75" onClick={() => handleRemoveEnv(index)}>
                          <i className="mx-1 far fa-trash-alt" />
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        )}
        {/* Deploy */}
        <Button
          disabled={isDeployingLoading}
          className="w-100"
          onClick={async (e) => {
            e.preventDefault();
            setAttemptedDeploy(true);
            if (
              !projectConfigurationInput.name ||
              allProjects.find((p: any) => p.name === projectConfigurationInput.name) ||
              !/^[a-zA-Z][-a-zA-Z0-9]*$/.test(projectConfigurationInput.name)
            ) {
              window.scrollTo({ top: 0, behavior: "smooth" });
              return;
            }
            // check if any env[].name is empty or any env[].value is empty
            const invalidEnv = env.some((e: any) => !e.name || !e.value);
            if (invalidEnv) {
              return;
            }
            if (isForkFlow && (!repositoryName || githubUserRepos.find((r: any) => r.name === repositoryName))) {
              window.scrollTo({ top: 0, behavior: "smooth" });
              return;
            }
            let finalProjectConfig = {};
            if (!framework) {
              finalProjectConfig = projectConfigurationInput;
            }
            if (framework === "next" || framework === "nuxt") {
              finalProjectConfig = {
                name: projectConfigurationInput.name,
                region: projectConfigurationInput.region,
                yamlVersion: 2,
              };
            } else {
              finalProjectConfig = {
                name: projectConfigurationInput.name,
                region: projectConfigurationInput.region,
                yamlVersion: 2,
                frontend: {
                  path: "./",
                  publish: frameworkSettings.buildOutput,
                  scripts: {
                    build: frameworkSettings.buildCommand,
                    deploy: frameworkSettings.installCommand,
                  },
                },
              };
            }
            setIsDeployingLoading(true);
            await handleDeployProject(
              framework,
              githubRepositoryId,
              selectedPath,
              finalProjectConfig,
              env,
              repositoryName,
              privateRepo,
            );
            setIsDeployingLoading(false);
          }}
          id={isForkFlow ? `${repoName}-create-btn` : "import-project-create-btn"}
        >
          Create {isDeployingLoading && <Spinner animation="border" size="sm" className="ms-1" />}
        </Button>
      </Col>
    </>
  );
};
export default ProjectConfiguration;
