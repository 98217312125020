import React from "react";
import { Navbar, DropdownButton, Dropdown } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import DeleteStageModal from "../../components/AdvancedUI/Modals/DeleteStage";
import { DEFAULT_STAGE_NAME } from "../../utils";
import { toast } from "react-toastify";
import { deleteStage } from "../../network/ApiAxios";
import { AxiosError, AxiosResponse } from "axios";

export default function Header(props: { allProjects: any[]; isSidebarToggled: any; openCloseSidebar: any }) {
  const { projectId, envId } = useParams();
  const { allProjects, isSidebarToggled, openCloseSidebar } = props;

  const [Lang, setLang] = React.useState(false);
  const [fullscreens, setFullscreen] = React.useState(true);
  const [userPhotoLink, setUserPhotoLink] = React.useState();
  const [userName, setUserName] = React.useState("User");
  // const [isSidebarToggled, setIsSidebarToggled] = React.useState(true);

  const defaultSelectedProject = allProjects?.find((project) => project?.id === projectId);

  React.useEffect(() => {
    //check if local storage has user data and its valid json
    if (localStorage.getItem("user") && localStorage.getItem("user") != "undefined") {
      const user = JSON.parse(localStorage.getItem("user")!);
      setUserPhotoLink(user.profileUrl);
      setUserName(user.name);
    }
  }, []);

  // FullScreen
  var elem: any = document.documentElement;
  const Fullscreen: any = (vale: any) => {
    switch (vale) {
      case true:
        elem.requestFullscreen();
        setFullscreen(false);
        break;
      case false:
        document.exitFullscreen();
        setFullscreen(true);
        break;
    }
  };

  //leftsidemenu
  // const openCloseSidebar = () => {
  //   setIsSidebarToggled((prev) => !prev);
  // };

  //rightsidebar
  const Rightsidebar = () => {
    document.querySelector(".sidebar-right")?.classList.add("sidebar-open");
  };

  React.useEffect(() => {
    const isDarkMode = localStorage.getItem("darkMode") === "true" || localStorage.getItem("darkMode") === null;
    Darkmode(isDarkMode);
  }, []);

  const Darkmode = (isEnabled: any) => {
    const appElement = document.querySelector(".app");
    appElement?.classList.toggle("dark-theme", isEnabled);
    localStorage.setItem("darkMode", isEnabled);
  };

  // responsivesearch
  const responsivesearch = () => {
    document.querySelector(".navbar-form")?.classList.toggle("active");
  };
  //swichermainright
  const swichermainright = () => {
    document.querySelector(".demo_changer")?.classList.toggle("active");
    let rightSidebar: any = document.querySelector(".demo_changer");
    rightSidebar.style.right = "0px";
  };
  const [price, setPrice] = React.useState(0);
  // console.log(price);

  let getdata: any = useSelector((state: any) => state.cartreducer.carts);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open: any = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    console.log(open);
  };

  const [Data, setData] = React.useState([]);

  React.useEffect(() => {
    const bodyElement = document.querySelector("body");
    if (bodyElement) {
      bodyElement.classList.toggle("sidenav-toggled");
    }
  }, [isSidebarToggled]);

  function total() {
    let price = 0;
    getdata.map((ele: any) => {
      price = ele.price * ele.qnty + price;
      return price;
    });
    setPrice(price);
  }

  React.useEffect(() => {
    total();
  });
  let navigate = useNavigate();
  const signOut = () => {
    localStorage.clear();
    navigate("/auth/login");
  };

  const [deleteStageModal, setDeleteStageModal] = React.useState(false);
  const [deleteStageInfo, setDeleteStageInfo] = React.useState({ projectId: "", stage: "" });

  return (
    <Navbar className="main-header side-header sticky nav nav-item">
      <div className="main-container container-fluid">
        <div
          className="main-header-left"
          style={{
            // width: "100%",
            // maxWidth: "30%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ margin: "0 20px 0 0" }}
            className="app-sidebar__toggle"
            data-bs-toggle="sidebar"
            onClick={() => openCloseSidebar()}
          >
            <div className="open-toggle" style={{ cursor: "pointer" }}>
              <i className="header-icon fe fe-align-left"></i>
            </div>
            <div className="close-toggle" style={{ cursor: "pointer" }}>
              <i className="header-icon fe fe-align-justify"></i>
            </div>
          </div>
          <div className="logo-horizontal">
            <Link to={`/`} className="header-logo">
              <img src={require("../../assets/img/brand/logo.svg").default} className="mobile-logo logo-1" alt="logo" />
              <img
                src={require("../../assets/img/brand/logo-white.svg").default}
                className="mobile-logo dark-logo-1"
                alt="logo"
              />
            </Link>
          </div>

          {projectId && defaultSelectedProject ? (
            <div
              style={{
                left: isSidebarToggled ? "200px" : "0px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <DropdownButton
                title={defaultSelectedProject?.name}
                id="dropdown-basic"
                className="ml-5"
                variant="primary"
              >
                {allProjects.map((project, index) => (
                  <Dropdown.Item
                    onClick={() => {
                      navigate(`/project/${project?.id}/${project?.projectEnvs[0]?.id}`);
                      window.location.reload();
                    }}
                    key={index}
                    href="#"
                  >
                    {project.name}
                  </Dropdown.Item>
                ))}

                <Dropdown.Item
                  onClick={() => {
                    navigate(`/new-project/`);
                  }}
                  href="#"
                >
                  New Project
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    navigate(`/`);
                  }}
                  href="#"
                >
                  All Projects
                </Dropdown.Item>
              </DropdownButton>
              <p className="m-0 fs-5 mx-2">&gt;</p>
              <DropdownButton
                title={defaultSelectedProject?.projectEnvs.find((env: any) => env.id === envId)?.name}
                id="dropdown-basic"
                variant="primary"
              >
                <DeleteStageModal
                  stageName={deleteStageInfo.stage}
                  handleDeleteStage={async () => {
                    const toastId = toast.loading("Deleting stage...");
                    const response: AxiosResponse | AxiosError = await deleteStage(
                      deleteStageInfo.projectId,
                      deleteStageInfo.stage,
                    );
                    if (response instanceof Error) {
                      let errorMessage = response.data?.error ? response.data.error.message : "";
                      toast.error(`Failed to delete stage! ${errorMessage}`, { updateId: toastId, toastId });
                      return;
                    } else {
                      toast.success("Stage deleted successfully!", { updateId: toastId, toastId });
                    }

                    const currentStage: string = defaultSelectedProject?.projectEnvs.find(
                      (env: any) => env.id === envId,
                    ).name;

                    if (currentStage === deleteStageInfo.stage) {
                      const firstStage = defaultSelectedProject?.projectEnvs.filter(
                        (env: any) => env.name !== deleteStageInfo.stage,
                      );
                      const envId = firstStage.length > 0 ? firstStage[0].id : undefined;

                      if (envId) {
                        navigate(`/project/${deleteStageInfo.projectId}/${envId}`);
                      } else {
                        navigate(`/dashboard`);
                      }
                    }

                    window.location.reload();
                  }}
                  show={deleteStageModal}
                  onHide={() => setDeleteStageModal(false)}
                />

                {defaultSelectedProject?.projectEnvs?.map((env: any, index: any) => (
                  <div className="d-flex flex-row align-items-center">
                    <Dropdown.Item
                      onClick={() => {
                        navigate(`/project/${defaultSelectedProject?.id}/${env?.id}`);
                        window.location.reload();
                      }}
                      key={index}
                      href="#"
                    >
                      {env.name}
                    </Dropdown.Item>{" "}
                    {env?.name !== DEFAULT_STAGE_NAME ? (
                      <a
                        onClick={() => {
                          setDeleteStageInfo({ projectId: defaultSelectedProject?.id, stage: env?.name });
                          setDeleteStageModal(true);
                        }}
                      >
                        <i className="fa fa-trash m-2" />
                      </a>
                    ) : null}
                  </div>
                ))}

                <Dropdown.Item target="_blank" href="https://genezio.com/docs/features/deployment-environments/">
                  Create New Environment
                </Dropdown.Item>
              </DropdownButton>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="main-header-right">
          <Link
            to="#"
            className="new nav-link theme-layout nav-link-bg layout-setting m-2 d-lg-none"
            onClick={() => Darkmode(localStorage.getItem("darkMode") === "true" ? false : true)}
          >
            <span className="dark-layout">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="header-icon-svgs"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M20.742 13.045a8.088 8.088 0 0 1-2.077.271c-2.135 0-4.14-.83-5.646-2.336a8.025 8.025 0 0 1-2.064-7.723A1 1 0 0 0 9.73 2.034a10.014 10.014 0 0 0-4.489 2.582c-3.898 3.898-3.898 10.243 0 14.143a9.937 9.937 0 0 0 7.072 2.93 9.93 9.93 0 0 0 7.07-2.929 10.007 10.007 0 0 0 2.583-4.491 1.001 1.001 0 0 0-1.224-1.224zm-2.772 4.301a7.947 7.947 0 0 1-5.656 2.343 7.953 7.953 0 0 1-5.658-2.344c-3.118-3.119-3.118-8.195 0-11.314a7.923 7.923 0 0 1 2.06-1.483 10.027 10.027 0 0 0 2.89 7.848 9.972 9.972 0 0 0 7.848 2.891 8.036 8.036 0 0 1-1.484 2.059z" />
              </svg>
            </span>
            <span className="light-layout">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="header-icon-svgs"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M6.993 12c0 2.761 2.246 5.007 5.007 5.007s5.007-2.246 5.007-5.007S14.761 6.993 12 6.993 6.993 9.239 6.993 12zM12 8.993c1.658 0 3.007 1.349 3.007 3.007S13.658 15.007 12 15.007 8.993 13.658 8.993 12 10.342 8.993 12 8.993zM10.998 19h2v3h-2zm0-17h2v3h-2zm-9 9h3v2h-3zm17 0h3v2h-3zM4.219 18.363l2.12-2.122 1.415 1.414-2.12 2.122zM16.24 6.344l2.122-2.122 1.414 1.414-2.122 2.122zM6.342 7.759 4.22 5.637l1.415-1.414 2.12 2.122zm13.434 10.605-1.414 1.414-2.122-2.122 1.414-1.414z" />
              </svg>
            </span>
          </Link>

          <div className="navresponsive-toggler d-lg-none ms-auto">
            {/* TODO: create component for user image  */}
            <Dropdown className=" main-profile-menu nav nav-item nav-link ps-lg-2">
              <Dropdown.Toggle className="new nav-link profile-user d-flex" variant="">
                {userPhotoLink !== "%!s(<nil>)" ? (
                  <img alt="" src={userPhotoLink} referrerPolicy="no-referrer" className="" />
                ) : (
                  <div
                    style={{
                      width: "37px",
                      height: "37px",
                      fontSize: "21px",
                      backgroundColor: "#6F42C1",
                    }}
                    className="rounded-circle d-flex align-items-center justify-content-center"
                  >
                    <p className="m-0  text-white ">{userName[0]?.toUpperCase()}</p>
                  </div>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="menu-header-content p-3 border-bottom">
                  <div className="d-flex wd-100p">
                    <div className="main-img-user">
                      {userPhotoLink !== "%!s(<nil>)" ? (
                        <img alt="" src={userPhotoLink} referrerPolicy="no-referrer" className="" />
                      ) : (
                        <div
                          style={{
                            width: "37px",
                            height: "37px",
                            fontSize: "21px",
                            backgroundColor: "#6F42C1",
                          }}
                          className="rounded-circle d-flex align-items-center justify-content-center"
                        >
                          <p className="m-0  text-white ">{userName[0]?.toUpperCase()}</p>
                        </div>
                      )}
                    </div>
                    <div className="ms-3 my-auto">
                      <h6 className="tx-15 font-weight-semibold mb-0">{userName}</h6>
                    </div>
                  </div>
                </div>

                <NavLink className="dropdown-item" to={`${process.env.PUBLIC_URL}/profile`}>
                  <i className="fa fa-user"></i> Profile
                </NavLink>

                <NavLink className="dropdown-item" to="/settings/tokens">
                  <i className="fas fa-key"></i>
                  Personal Access Tokens
                </NavLink>

                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    signOut();
                  }}
                >
                  <i className="far fa-arrow-alt-circle-left"></i> Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="mb-0 navbar navbar-expand-lg   navbar-nav-right responsive-navbar navbar-dark p-0">
            <Navbar.Collapse className="collapse" id="navbarSupportedContent-4">
              <ul className="nav nav-item header-icons navbar-nav-right">
                <li className="dropdown nav-item">
                  <Link
                    to="#"
                    className="new nav-link theme-layout nav-link-bg layout-setting"
                    onClick={() => Darkmode(localStorage.getItem("darkMode") === "true" ? false : true)}
                  >
                    <span className="dark-layout">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="header-icon-svgs"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20.742 13.045a8.088 8.088 0 0 1-2.077.271c-2.135 0-4.14-.83-5.646-2.336a8.025 8.025 0 0 1-2.064-7.723A1 1 0 0 0 9.73 2.034a10.014 10.014 0 0 0-4.489 2.582c-3.898 3.898-3.898 10.243 0 14.143a9.937 9.937 0 0 0 7.072 2.93 9.93 9.93 0 0 0 7.07-2.929 10.007 10.007 0 0 0 2.583-4.491 1.001 1.001 0 0 0-1.224-1.224zm-2.772 4.301a7.947 7.947 0 0 1-5.656 2.343 7.953 7.953 0 0 1-5.658-2.344c-3.118-3.119-3.118-8.195 0-11.314a7.923 7.923 0 0 1 2.06-1.483 10.027 10.027 0 0 0 2.89 7.848 9.972 9.972 0 0 0 7.848 2.891 8.036 8.036 0 0 1-1.484 2.059z" />
                      </svg>
                    </span>
                    <span className="light-layout">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="header-icon-svgs"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M6.993 12c0 2.761 2.246 5.007 5.007 5.007s5.007-2.246 5.007-5.007S14.761 6.993 12 6.993 6.993 9.239 6.993 12zM12 8.993c1.658 0 3.007 1.349 3.007 3.007S13.658 15.007 12 15.007 8.993 13.658 8.993 12 10.342 8.993 12 8.993zM10.998 19h2v3h-2zm0-17h2v3h-2zm-9 9h3v2h-3zm17 0h3v2h-3zM4.219 18.363l2.12-2.122 1.415 1.414-2.12 2.122zM16.24 6.344l2.122-2.122 1.414 1.414-2.122 2.122zM6.342 7.759 4.22 5.637l1.415-1.414 2.12 2.122zm13.434 10.605-1.414 1.414-2.122-2.122 1.414-1.414z" />
                      </svg>
                    </span>
                  </Link>
                </li>
                <li className="nav-item full-screen fullscreen-button" onClick={() => Fullscreen(fullscreens)}>
                  <Link className="new nav-link full-screen-link" to="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="header-icon-svgs"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 5h5V3H3v7h2zm5 14H5v-5H3v7h7zm11-5h-2v5h-5v2h7zm-2-4h2V3h-7v2h5z" />
                    </svg>
                  </Link>
                </li>
                <Dropdown className=" main-profile-menu nav nav-item nav-link ps-lg-2">
                  <Dropdown.Toggle className="new nav-link profile-user d-flex" variant="">
                    {userPhotoLink !== "%!s(<nil>)" ? (
                      <img alt="" src={userPhotoLink} referrerPolicy="no-referrer" className="" />
                    ) : (
                      <div
                        style={{
                          width: "37px",
                          height: "37px",
                          fontSize: "21px",
                          backgroundColor: "#6F42C1",
                        }}
                        className="rounded-circle d-flex align-items-center justify-content-center"
                      >
                        <p className="m-0  text-white ">{userName[0]?.toUpperCase()}</p>
                      </div>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="menu-header-content p-3 border-bottom">
                      <div className="d-flex wd-100p">
                        <div className="main-img-user">
                          {userPhotoLink !== "%!s(<nil>)" ? (
                            <img alt="" src={userPhotoLink} referrerPolicy="no-referrer" className="" />
                          ) : (
                            <div
                              style={{
                                width: "37px",
                                height: "37px",
                                fontSize: "21px",
                                backgroundColor: "#6F42C1",
                              }}
                              className="rounded-circle d-flex align-items-center justify-content-center"
                            >
                              <p className="m-0  text-white ">{userName[0]?.toUpperCase()}</p>
                            </div>
                          )}
                        </div>
                        <div className="ms-3 my-auto">
                          <h6 className="tx-15 font-weight-semibold mb-0">{userName}</h6>
                        </div>
                      </div>
                    </div>

                    <NavLink className="dropdown-item" to={`${process.env.PUBLIC_URL}/profile`}>
                      <i className="fa fa-user"></i> Profile
                    </NavLink>

                    <NavLink className="dropdown-item" to="/settings/tokens">
                      <i className="fas fa-key"></i>
                      Personal Access Tokens
                    </NavLink>

                    <Dropdown.Item
                      className="dropdown-item"
                      onClick={() => {
                        signOut();
                      }}
                    >
                      <i className="far fa-arrow-alt-circle-left"></i> Sign Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </Navbar.Collapse>
          </div>
        </div>
      </div>
    </Navbar>
  );
}

Header.propTypes = {};

Header.defaultProps = {};
