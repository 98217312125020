import React, { useEffect, useState } from "react";
import { Col, Row, Card, Button, Breadcrumb, Spinner, Tabs, Tab, OverlayTrigger, Tooltip } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { getUserInformation } from "../network/ApiAxios";

export default function Profile() {
  const pageKey = "profile";
  const navigation = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<any>({}); // user info
  const [signinMethod, setSigninMethod] = useState<any>(""); // user info

  React.useEffect(() => {
    document.title = "Genezio | Profile";
  }, []);

  useEffect(() => {
    async function asyncFetch() {
      setIsLoading(true);
      const resp: any = await getUserInformation();
      setIsLoading(false);

      if (resp.response && resp.response.data.error) {
        alert(resp.response.data.error.message);
        return;
      }

      if (resp.data && resp.data.error) {
        alert(resp.data.error.message);
        return;
      }

      if (resp.data && resp.data.user) {
        setUserInfo(resp.data.user);
        if (resp.data.user.profileUrl.includes("google")) {
          setSigninMethod("Google");
        } else if (resp.data.user.profileUrl.includes("github")) {
          setSigninMethod("GitHub");
        }
        return;
      }
    }
    asyncFetch();
  }, []);

  return (
    <React.Fragment>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item
              className="breadcrumb-item"
              onClick={() => navigation("/")}
              aria-current="page"
              style={{ paddingTop: "1px" }} // hack don;t change it
            >
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb-item" aria-current="page" active>
              Profile
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      {/* <!-- row  --> */}
      <Row>
        <Col sm={12} className="col-12">
          <Card className="pb-4">
            <Tabs
              id="controlled-tab-example"
              activeKey={pageKey}
              onSelect={(k) => {
                if (k === "profile") navigation("/profile");
                if (k === "billing") navigation("/billing");
              }}
            >
              <Tab eventKey="profile" title="Profile" active disabled></Tab>
              <Tab eventKey="billing" title="Billing"></Tab>
            </Tabs>
            <Card.Header>
              <h5 className="card-title" style={{ textTransform: "none" }}>
                General Information
              </h5>
            </Card.Header>
            <Card.Body className="pt-0 example1-table card-body mx-4 card-billing-info p-0">
              <Row className="p-4 m-0 profile-elem-border">
                <Col sm={4} className="col-12">
                  <span className="text-elem-left-profile darkText">Name</span>
                </Col>
                <Col sm={8} className="col-12">
                  {isLoading ? (
                    <Spinner animation="border" variant="primary" className="profile-spinner" />
                  ) : (
                    userInfo.name
                  )}
                </Col>
              </Row>
              <Row className="p-4 m-0 profile-elem-border">
                <Col sm={4} className="col-12">
                  <span className="text-elem-left-profile darkText">Sign-in Method</span>
                </Col>
                <Col sm={8} className="col-12">
                  {isLoading ? (
                    <Spinner animation="border" variant="primary" className="profile-spinner" />
                  ) : (
                    <>
                      {signinMethod} - {userInfo.email}
                    </>
                  )}
                </Col>
              </Row>
              <Row className="p-4 m-0 profile-elem-border">
                <Col sm={4} className="col-12">
                  <span className="text-elem-left-profile darkText">Subscription Plan</span>
                </Col>
                <Col sm={6} className="col-12">
                  {isLoading ? (
                    <Spinner animation="border" variant="primary" className="profile-spinner" />
                  ) : (
                    <>
                      {userInfo.subscriptionPlan} Plan
                      <br />
                      <a href="https://genezio.com/eula/" target="_blank" rel="noreferrer">
                        Terms and Conditions
                      </a>
                    </>
                  )}
                </Col>
                <Col sm={2} className="col-12 d-flex justify-content-end p-0 m-0">
                  <span>
                    <Button
                      className="custom-border-btn btn-outline-primary"
                      onClick={() => navigation("/billing?plans_modal=true")}
                    >
                      Upgrade
                    </Button>
                  </span>
                </Col>
              </Row>
              <Row className="p-4 m-0">
                <Col sm={4} className="col-12">
                  <span className="text-elem-left-profile darkText">Member Since</span>
                </Col>
                <Col sm={8} className="col-12">
                  {isLoading ? (
                    <Spinner animation="border" variant="primary" className="profile-spinner" />
                  ) : (
                    <>{userInfo.memberSince}</>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
