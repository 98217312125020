export const timelineOptions = [
  { label: "Last 1 minute", value: 1 * 60 * 1000 },
  { label: "Last 5 minutes", value: 5 * 60 * 1000 },
  { label: "Last 15 minutes", value: 15 * 60 * 1000 },
  { label: "Last 30 minutes", value: 30 * 60 * 1000 },
  { label: "Last 1 hour", value: 3600 * 1000 },
  { label: "Last 12 hours", value: 12 * 3600 * 1000 },
  { label: "Last 24 hours", value: 24 * 3600 * 1000 },
  { label: "Last 48 hours", value: 48 * 3600 * 1000 },
  { label: "Custom", value: "custom" },
];
